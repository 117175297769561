import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Button,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "./Blog.scss";
import Footer from "components/Footer";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useParams } from "react-router-dom";
import axios from "axios";


function BreadcrumbExample({ blog }) {
  return (
    <Breadcrumb className="ps-4">
      <Breadcrumb.Item href="/blogs" className="text-decoration-none">Blogs</Breadcrumb.Item>
      {/* <Breadcrumb.Item href="#" className="text-decoration-none">
        Stories
      </Breadcrumb.Item> */}
      <Breadcrumb.Item active style={{ fontWeight: 'bolder', color: 'black' }}>{blog.title}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

const Blog = () => {
  const params = useParams();
  const [blog, setBlog] = useState({})
  console.log({ params });

  const fetchBlog = async () => {
    try {
      let res = await axios.get("/blogs/" + params.slug);
      console.log(res)
      if (res) {
        setBlog(res.data)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => fetchBlog(), [])


  return (
    <>
      <Container
        className="p-0 mt-5 mb-5 pt-5 d-flex flex-column"
        fluid
        style={styles.parentContainer}
      >
        <Container fluid className="px-md-5 d-flex flex-column justify-content-between align-items-center">
          <Row className="mb-8" style={{ alignSelf: 'start' }}>
            <BreadcrumbExample blog={blog} />
          </Row>
          {/* Image */}
          <Row className="mb-8 w-100">
            <img src={blog?.blog_image} alt={blog?.title} style={styles.images} />
          </Row>
          {/* Content */}
          <Row className="mb-8 w-100" >
            <h3 className="ps-2 blog-title">{blog?.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: blog?.content }}></p>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    maxWidth: 992 + "px",
  },
  images: {

    width: 100 + '%',
    objectFit: 'cover'
  }
};

export default Blog;
