import React from "react";
import { Container, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const handleNavigationClick = (url) => {
    window.location.href = url;
  };
  return (
    <>
      <Container
        className="p-0 pt-5 d-flex justify-content-center align-items-center"
        fluid
        style={styles.parentContainer}
      >
        <Container
          fluid
          className="px-md-5 d-flex justify-content-around align-items-center flex-column"
          style={{ minHeight: "50vh" }}
        >
          <h1 style={styles.h1}>Page Not Found</h1>
          <Image
            src="/assets/svgs/page-not-found/pageNotFound.svg"
            alt="Page Not Found"
          />
          {/* <Image
            src="/assets/clients/northern_trust.png"
            alt="Page Not Found"
          />
          <Image
            src="/assets/clients/page_not_found.png"
            // src="/assets/clients/kantar.png"
            alt="Page Not Found"
          />
          <Image
            src="/assets/clients/page_not_found.webp"
            // src="/assets/clients/kantar.png"
            alt="Page Not Found"
          />
          <Image
            src="/assets/clients/page_not_found.jpg"
            alt="Page Not Found"
          />
          <Image
            src="/assets/clients/page_not_found.svg"
            // src="/assets/clients/kantar.png"
            alt="Page Not Found"
          /> */}
          <h4 style={styles.h4}>
            We're sorry, the page you requested could not be found
            <br />
            Please go back to the homepage
          </h4>
          <Button
            // as={Link}
            // to="/home"
            className="btn-main ms-3 my-1"
            onClick={() => {
              handleNavigationClick("/home");
            }}
          >
            BACK TO HOME
          </Button>
        </Container>
      </Container>
    </>
  );
};

const styles = {
  parentContainer: {
    backgroundColor: "#F5FFFA",
    height: "100vh",
    fontFamily: "Montserrat Regular",
  },
  h1: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "44px",
    textAlign: "center",
    color: "#02493C",
  },
  h4: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#02493C",
  },
};

export default PageNotFound;
