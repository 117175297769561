import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";

import { useSpring, animated, useSpringRef } from "react-spring";

import { Breakpoint } from "react-socks";
import NavBar from "components/NavBar";
import "./Explore.scss";
import { useDispatch, useSelector } from "react-redux";
import { switchMode } from "actions";
import { Link, useLocation } from "react-router-dom";
import * as easings from "d3-ease";
import { Helmet } from "react-helmet";
import { resetAllPages } from "actions";

const Explore = () => {
  const [candRecVisible, setCandRecVisible] = useState(0);
  const dispatch = useDispatch();

  const [isAnimationOver, setIsAnimationOver] = useState(false);

  const springProps = useSpring({
    from: { opacity: 0, height: "0%" },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: { opacity: 1, height: "100%" },
    config: { duration: 1000, tension: 100 },
    onRest: () => {
      setCandRecVisible(1);
    },
    delay: 1000,
  });

  const springProps1 = useSpring({
    from: { recruiterX: 0, candidateX: 0 },
    // to: { recruiterX: 0, candidateX: 0 },
    to: { recruiterX: -300, candidateX: 200 },
    config: { duration: 1000, easing: easings.easeCubicOut },
    delay: 2500,
    onRest: () => setIsAnimationOver(true),
  });

  const scaleRef = useSpringRef();

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const [
    {
      scaleCandidate,
      scaleRecruiter,
      fontWeightCandidate,
      fontWeightRecruiter,
    },
    springPropsHover,
  ] = useSpring(() => ({
    scaleRecruiter: 1,
    scaleCandidate: 1,
    fontWeightRecruiter: 400,
    fontWeightCandidate: 400,
    scaleRef,
  }));

  const location = useLocation();

  useEffect(() => {
    dispatch(resetAllPages());
  }, [])

  return (
    <>
      {/* <Helmet>
        <title>{`Open Roles in IT &in Banking|Open Roles for Senior Positions`}</title>
        <meta name="description" content='Job Consultancy for Open roles for Senior postions in Banking and in IT.  Explore PAN India jobs and open roles and find jobs that help you grow.' />
        <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="p-0 pt-5 d-flex flex-column justify-content-center"
        fluid
        style={styles.parentContainer}
      >
        {/* <NavBar /> */}
        <Container className="px-4" fluid>
          <Row>
            <Col lg={12} className="text-center pt-4">
              <Breakpoint medium down>
                <h1 className="explore-main-text">Make A Choice Please...</h1>
                <h2 className="explore-main-text">
                  Tell Us Why Are You
                  <br className="d-block d-md-none" /> Here Today
                </h2>
              </Breakpoint>
              <Breakpoint large up>
                <h1 className="explore-main-text">
                  Make A Choice Please... Tell Us Why
                </h1>
                <h2 className="explore-main-text">Are You Here Today</h2>
              </Breakpoint>
            </Col>
          </Row>
          {/* <Row style={{ opacity: candRecVisible ? 0 : 1 }}>
              <Col lg={12} className="text-center pt-1 explore-image">
                  <animated.div style={{ opacity: springProps.opacity, height: springProps.height }} >
                      <Image style={{ height: "100%" }} src="/assets/svgs/explore.svg" />
                  </animated.div>
              </Col>
          </Row> */}
          <Breakpoint large down>
            <Row>
              <Col lg={12} className="text-center py-3">
                <Image
                  alt="Explore our HR Services"
                  className="explore-image"
                  src="/assets/svgs/explore.svg"
                />
              </Col>
            </Row>
            <div className="d-grid gap-4 my-2">
              <Button
                as={Link}
                to="/home"
                className="btn-main-outline"
                variant="outline-primary"
                onClick={() => {
                  dispatch(switchMode("candidate"));
                }}
              >
                I AM CANDIDATE
              </Button>
              <Button
                as={Link}
                to="/home"
                className="btn-main-outline"
                variant="outline-primary"
                onClick={() => {
                  dispatch(switchMode("recruiter"));
                }}
              >
                I AM RECRUITER
              </Button>
            </div>
          </Breakpoint>
          <Breakpoint xlarge up>
            <Row style={{ opacity: candRecVisible ? 0 : 1 }}>
              <Col lg={12} className="text-center pt-1 explore-image">
                <animated.div
                  style={{
                    opacity: springProps.opacity,
                    height: springProps.height,
                  }}
                >
                  <Image
                    style={{ height: "100%" }}
                    src="/assets/svgs/explore.svg"
                    alt="Explore our HR Services"
                  />
                </animated.div>
              </Col>
            </Row>
            <Row style={{ opacity: candRecVisible }}>
              <Col
                lg={6}
                className="d-flex justify-content-end h-100 align-items-end"
                style={{ position: "relative" }}
              >
                <animated.div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    marginRight: -160,
                    translateX: springProps1.recruiterX,
                    scaleX: scaleRecruiter,
                    scaleY: scaleRecruiter,
                    transformOrigin: "left bottom",
                  }}
                >
                  <Link
                    to="/home"
                    onClick={() => {
                      dispatch(switchMode("recruiter"));
                    }}
                  >
                    <Image
                      className="explore-recruiter"
                      src="/assets/svgs/explore_recruiter.svg"
                      alt="explore recruiter"
                      onMouseEnter={async () => {
                        if (isAnimationOver)
                          await springPropsHover.start({
                            scaleRecruiter: 1.2,
                            fontWeightRecruiter: 700,
                          });
                      }}
                      onMouseLeave={async () => {
                        if (isAnimationOver)
                          await springPropsHover.start({
                            scaleRecruiter: 1,
                            fontWeightRecruiter: 400,
                          });
                      }}
                    />
                  </Link>
                </animated.div>
              </Col>
              <Col
                lg={6}
                className="d-flex justify-content-start"
                style={{ position: "relative" }}
              >
                <animated.div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    marginLeft: 30,
                    translateX: springProps1.candidateX,
                    scaleX: scaleCandidate,
                    scaleY: scaleCandidate,
                    transformOrigin: "right bottom",
                  }}
                >
                  <Link
                    // to="/home"
                    href="/home"
                    onClick={() => {
                      dispatch(switchMode("candidate"));
                    }}
                  >
                    <Image
                      className="explore-candidate"
                      src="/assets/svgs/explore_candidate.svg"
                      alt="explore candidate"
                      onMouseEnter={async () => {
                        if (isAnimationOver)
                          await springPropsHover.start({
                            scaleCandidate: 1.2,
                            fontWeightCandidate: 700,
                          });
                      }}
                      onMouseLeave={async () => {
                        if (isAnimationOver)
                          await springPropsHover.start({
                            scaleCandidate: 1,
                            fontWeightCandidate: 400,
                          });
                      }}
                    />
                  </Link>
                </animated.div>
              </Col>
              <Col
                lg={12}
                className="d-flex justify-content-center"
                style={{ position: "relative" }}
              >
                <Image
                  src="/assets/svgs/explore_baseline.svg"
                  alt="explore-baseline"
                  style={{ position: "absolute", bottom: 0 }}
                />
              </Col>
              <Col lg={6} className="mt-3">
                <animated.div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ fontWeight: fontWeightRecruiter }}
                >
                  <Link
                    to="/home"
                    className="links text-center"
                    onMouseEnter={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleRecruiter: 1.25,
                          fontWeightRecruiter: 700,
                        });
                    }}
                    onMouseLeave={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleRecruiter: 1,
                          fontWeightRecruiter: 400,
                        });
                    }}
                    onClick={() => {
                      dispatch(switchMode("recruiter"));
                    }}
                  >
                    I am trying to find the <br /> right candidate for me
                  </Link>
                  <Button
                    as={Link}
                    to="/home"
                    onClick={() => {
                      dispatch(switchMode("recruiter"));
                    }}
                    variant="outline-primary"
                    className="my-3 align-items-center btn-redirect btn-main-outline"
                    onMouseEnter={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleRecruiter: 1.25,
                          fontWeightRecruiter: 700,
                        });
                    }}
                    onMouseLeave={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleRecruiter: 1,
                          fontWeightRecruiter: 400,
                        });
                    }}
                  >
                    I AM RECRUITER
                  </Button>
                </animated.div>
              </Col>
              <Col lg={6} className="mt-3">
                <animated.div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ fontWeight: fontWeightCandidate }}
                >
                  <Link
                    // to="/home"
                    href="/home"
                    className="links text-center"
                    onMouseEnter={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleCandidate: 1.25,
                          fontWeightCandidate: 700,
                        });
                    }}
                    onMouseLeave={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleCandidate: 1,
                          fontWeightCandidate: 400,
                        });
                    }}
                    onClick={() => {
                      dispatch(switchMode("candidate"));
                    }}
                  >
                    I am trying to find <br /> the right job for me
                  </Link>
                  <Button
                    as={Link}
                    to="/home"
                    onClick={() => {
                      dispatch(switchMode("candidate"));
                    }}
                    variant="outline-primary"
                    className="my-3 align-items-center btn-redirect btn-main-outline"
                    onMouseEnter={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleCandidate: 1.25,
                          fontWeightCandidate: 700,
                        });
                    }}
                    onMouseLeave={async () => {
                      if (isAnimationOver)
                        await springPropsHover.start({
                          scaleCandidate: 1,
                          fontWeightCandidate: 400,
                        });
                    }}
                  >
                    I AM CANDIDATE
                  </Button>
                </animated.div>
              </Col>
            </Row>
          </Breakpoint>
        </Container>
      </Container>
    </>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Explore;
