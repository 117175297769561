import React, { useState } from "react";
import { Container, Row, Col, Image, Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { useCurrentBreakpointName } from "react-socks";
import { increment1, decrement1 } from "actions";
import { Link } from "react-router-dom";
import NavBar from "components/NavBar";

import "./HomeCandidate.scss";

const clients = [
  {
    id: 1,
    set: [
      { id: 1, name: "HSBC", alt: "HSBC Bank", img: "/assets/clients/jp_morgan.png" },
      {
        id: 2,
        name: "Credit Suisse",
        img: "/assets/clients/credit_suisse.png",
        alt: "Credit Suisse"
      },
      {
        id: 3,
        name: "Northern Trust",
        img: "/assets/clients/northern_trust.png",
        alt: "Northern Trust",
      },
      { id: 4, name: "Deutsche bank", alt: "Deutsche bank", img: "/assets/clients/deutsche_bank.png" },
      { id: 5, name: "HSBC", alt: "HSBC Bank", img: "/assets/clients/hsbc.png" },
      {
        id: 6,
        name: "FIS",
        img: "/assets/clients/fis.png",
        alt: "FIS"
      },
      { id: 7, name: "Cognizant", alt: "Cognizant", img: "/assets/clients/cognizant.png" },
    ],
  },
  {
    id: 2,
    set: [
      { id: 8, name: "Yash Technology", alt: "Yash Technology", img: "/assets/images/clients/yash_technologies.png" },
      {
        id: 9,
        name: "Sopra Steria",
        img: "/assets/images/clients/sopra_steria.png",
        alt: "Sopra Steria"
      },
      {
        id: 10,
        name: "M&G Investment",
        img: "/assets/images/clients/mandg.png",
        alt: "M&G Investment"
      },
      { id: 11, name: "EMC", alt: "EMC", img: "/assets/images/clients/emc.png" },
      {
        id: 12,
        name: "Fractal",
        img: "/assets/images/clients/fractal.png",
        alt: "Fractal"
      },
      {
        id: 13,
        name: "eClerx",
        img: "/assets/images/clients/eclerx.png",
        alt: "eClerx"
      },
      { id: 14, name: "Berkadia", alt: "Berkadia", img: "/assets/images/clients/berkadia.png" },
    ],
  },
];

const Candidate4 = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(true);

  const count = useSelector((state) => state.counters);

  let scrolled = false;

  const cardImgTransition = useTransition(toggle, {
    from:
      count.candidatePrevState > count.candidateCurrentState
        ? { y: 0, opacity: 0 }
        : { y: 200, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 200, opacity: 0 },
    config: { duration: 1000, tenstion: 200 },
    onRest: () => {
      scrolled = true;
    }
  });

  const [toggleArrow, setToggleArrow] = useState(true);

  const arrowTransition = useTransition(toggleArrow, {
    from: { translateNext: 200, translatePrev: -200 },
    enter: { translateNext: 0, translatePrev: 0 },
    // leave: { y: 200, opacity: 0 },
    config: { duration: 1000, tenstion: 200 },
  });

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        dispatch(decrement1());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (scrolled) {
        dispatch(increment1());
      }
    }
  };

  return (
    <Container
      className="p-0 pt-5 d-flex flex-column"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container
        className="h-100 d-flex flex-column justify-content-center align-items-center"
        fluid
      >
        <Row>
          <Col
            lg={12}
            className=" mt-5"

          >
            <h1 className="recruiter-main-sidetext text-center" style={{ fontWeight: 600 }}>Companies you can work for</h1>
          </Col>
          <Col className="px-5 d-lfex justify-content-center" lg={12} style={{ overflow: 'hidden' }}>
            <Carousel
              className="py-5"
              prevIcon={arrowTransition((props, item) =>
                item ? (
                  <animated.div style={{ translateX: props.translatePrev }}>
                    <Image
                      src="/assets/svgs/candidate/candidate3/carousel-arrow-left.svg"
                      alt="carousel-arrow-left"
                      className="candidate3-carousel-arrow"
                      style={{ marginLeft: 150 }}
                    />
                  </animated.div>
                ) : (
                  <div />
                )
              )}
              indicators={false}
              prevLabel={null}
              nextLabel={null}
              nextIcon={arrowTransition((props, item) =>
                item ? (
                  <animated.div style={{ translateX: props.translateNext }}>
                    <Image
                      className="candidate3-carousel-arrow"
                      src="/assets/svgs/candidate/candidate3/carousel-arrow-right.svg"
                      alt="candidate3-carousel-arrow"
                      style={{ marginRight: 150 }}
                    />
                  </animated.div>
                ) : (
                  <div />
                )
              )}
              style={{ width: "100%" }}
              fade
            >
              {clients.map((item, index) => {
                return (
                  <Carousel.Item
                    key={item.id}
                    className="p-4 d-flex justify-content-center"
                    style={{ width: "100%" }}
                  >
                    {cardImgTransition((props, element) =>
                      element ? (
                        <Container as={animated.div} style={props}>
                          <div className="d-flex justify-content-center mb-4">
                            <div className="company-box d-flex justify-content-center align-items-center mx-5">
                              <Image
                                className="company-box-img"
                                src={item.set[0].img}
                                alt={item.set[0].alt}
                              />
                            </div>
                            <div className="company-box d-flex justify-content-center align-items-center mx-5">
                              <Image
                                className="company-box-img"
                                src={item.set[1].img}
                                alt={item.set[1].alt}
                              />
                            </div>
                            <div className="company-box d-flex justify-content-center align-items-center mx-5">
                              <Image
                                className="company-box-img"
                                src={item.set[2].img}
                                alt={item.set[2].alt}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mt-4">
                            <div className="company-box d-flex justify-content-center align-items-center mx-5">
                              <Image
                                className="company-box-img"
                                src={item.set[3].img}
                                alt={item.set[3].alt}
                              />
                            </div>
                            <div className="company-box d-flex justify-content-center align-items-center mx-5">
                              <Image
                                className="company-box-img"
                                src={item.set[4].img}
                                alt={item.set[4].alt}
                              />
                            </div>
                            <div className="company-box d-flex justify-content-center align-items-center mx-5">
                              <Image
                                className="company-box-img"
                                src={item.set[5].img}
                                alt={item.set[5].alt}
                              />
                            </div>
                            <div className="company-box d-flex justify-content-center align-items-center mx-5">
                              <Image
                                className="company-box-img"
                                src={item.set[6].img}
                                alt={item.set[6].alt}
                              />
                            </div>
                          </div>
                        </Container>
                      ) : (
                        <div />
                      )
                    )}
                  </Carousel.Item>
                );
              })}
            </Carousel>
            <div
              className="d-flex flex-column position-fixed"
              style={{ right: 50, bottom: 50, zIndex: 1000 }}
            >
              <Image
                className="arrow my-2"
                src="/assets/svgs/candidate/arrow_up.svg"
                alt="Arrow up"
                onClick={() => {
                  dispatch(decrement1());
                }}
              />
              <Image
                className="arrow"
                src="/assets/svgs/candidate/arrow_down.svg"
                alt="Arrow Down"
                onClick={() => {
                  dispatch(increment1());
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Candidate4;
