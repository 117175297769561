import React, { useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import customHistory from "customHistory";
import { useParams } from "react-router-dom";

const Rocket = () => {
  const { slug } = useParams();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = `/landing/apply/${slug}`;
    }, 5000);
  }, []);

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={styles.parentContainer}
    >
      <Image src="/assets/landing/rocket.gif" alt="Rocket"/>
    </Container>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
  },
};

export default Rocket;
