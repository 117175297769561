import React, { useState, useEffect } from "react";

// import logo from "./assets/png/znanye-3d-01-1@2x.png";
// import { overrideThemeVariables, Button } from "ui-neumorphism";
import Navigation from "./navigation";
import "bootstrap/dist/css/bootstrap.min.css";

import "./index.scss";

import logo from "./logo.svg";
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import { ConnectedRouter } from "connected-react-router";

import "./App.scss";
import { Provider } from "react-redux";
import { persister, store } from "./store";

const App = (props) => {

  useEffect(() => {}, []);

  setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 768 }, // ipad, ipad pro, ipad mini, etc
    { large: 992 }, // smaller laptops
    { xlarge: 1200 }, // laptops and desktops
    { xxlarge: 1900 }, // laptops and desktops
  ]);

  console.log(props)
  console.log(store)
        
  return (
    <BreakpointProvider>
      <Provider store={store}>
        {/* <ConnectedRouter history={history}> */}
        <Navigation />
        {/* </ConnectedRouter> */}
      </Provider>
    </BreakpointProvider>
  );
};

export default App;
