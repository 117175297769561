import React, { useEffect, useState } from "react";

import { Container, Navbar, Button, Row, Col, Image } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";

import ClientCard from "components/ClientCard";
import NavBar from "components/NavBar";
import "./Home.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useSpring, animated, useTrail, config } from "react-spring";
import * as easings from "d3-ease";

import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Footer from "components/Footer";

const clients = [
  { id: 1, name: "JP-Morgan", alt: "JP Morgan", img: "/assets/clients/jp_morgan.png" },
  { id: 2, name: "Credit Suisse", alt: "Credit suisse", img: "/assets/clients/credit_suisse.png" },
  { id: 3, name: "Northern Trust", alt: "Northern trust", img: "/assets/clients/northern_trust.png" },
  { id: 7, name: "CTS", alt: "Cognizant", img: "/assets/clients/cognizant.png" },
  { id: 6, name: "Fis", alt: "FIS", img: "/assets/clients/fis.png" },
  { id: 4, name: "Deutsche Bank", alt: "Deutsche bank", img: "/assets/clients/deutsche_bank.png" },
  { id: 5, name: "HSBC", alt: "HSBC Bank", img: "/assets/clients/hsbc.png" },
  { id: 15, name: "Cargill", alt: "Cargill", img: "/assets/clients/cargill.png" },
  {
    id: 8,
    name: "Yash Technologies",
    img: "/assets/images/clients/yash_technologies.png",
    alt: "Yash Tecnology"
  },
  {
    id: 9,
    name: "Sopra Steria",
    img: "/assets/images/clients/sopra_steria.png",
    alt: "Sopra Steria"
  },
  { id: 10, name: "MANDG", alt: "M&G Investment", img: "/assets/images/clients/mandg.png" },
  { id: 11, name: "Fractal", alt: "Fractal", img: "/assets/images/clients/fractal.png" },
  { id: 12, name: "EMC", alt: "EMC", img: "/assets/images/clients/emc.png" },
  { id: 13, name: "Eclerx", alt: "eClerx", img: "/assets/images/clients/eclerx.png" },
  { id: 14, name: "Berkadia", alt: "Berkadia", img: "/assets/images/clients/berkadia.png" },
  { id: 16, name: "Deloitte", alt: "Deloitte", img: "/assets/images/clients/deloitte.png" },
  { id: 17, name: "CitiBank", alt: "Citibank", img: "/assets/images/clients/citibank.png" },
  { id: 18, name: "Priceline", alt: "Priceline", img: "/assets/images/clients/priceline.png" },
  { id: 19, name: "Kantar", alt: "Kantar", img: "/assets/images/clients/kantar.png" },
  { id: 20, name: "VI", alt: "Vodafone Idea", img: "/assets/images/clients/vi.png" },
];

const Home = () => {
  const breakpoint = useCurrentBreakpointName();
  const location = useLocation();
  let wheelWidth = 0;
  let wheelBorderRadius = 0;
  let imageSrc = "";
  let alt = "";
  let wheelTop = 0;
  let wheelLeft = 0;
  let wheelLeftForRight = 0;
  let leftDiff = 0;

  switch (breakpoint) {
    case "xxlarge":
      wheelWidth = 2200;
      wheelBorderRadius = 1100;
      imageSrc = "/assets/images/home/dial.png";
      alt = "Dial";
      wheelTop = -1000;
      wheelLeft = -1550;
      leftDiff = -65;
      break;
    case "xlarge":
      wheelWidth = 2200;
      wheelBorderRadius = 1100;
      imageSrc = "/assets/images/home/dial.png";
      alt = "Dial";
      wheelTop = -1000;
      wheelLeft = -1670;
      wheelLeftForRight = -800;
      leftDiff = -65;
      break;
    case "large":
      wheelWidth = 2200;
      wheelBorderRadius = 1100;
      imageSrc = "/assets/images/home/dial.png";
      alt = "Dial";
      wheelTop = -1000;
      wheelLeft = -1670;
      wheelLeftForRight = -800;
      leftDiff = -65;
      break;
    case "medium":
      wheelWidth = 1320;
      wheelBorderRadius = 660;
      imageSrc = "/assets/images/home/dial-mobile.png";
      alt = "Dial Mobile";
      wheelTop = -597;
      wheelLeft = -830;
      leftDiff = -40;
      break;
    default:
      wheelWidth = 1320;
      wheelBorderRadius = 660;
      imageSrc = "/assets/images/home/dial-mobile.png";
      alt = "Dial Mobile";
      wheelTop = -603;
      wheelLeft = -1030;
      leftDiff = -40;
  }

  const [animatingLeft, setAnimatingLeft] = useState(true);
  const [animatingRight, setAnimatingRight] = useState(true);

  const springProps1 = useSpring({
    from: {
      index1: `rotate(-18deg)`,
      index2: `rotate(-12deg)`,
      index3: `rotate(-6deg)`,
      index4: `rotate(-24deg)`,
      index5: `rotate(-6deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-120000deg)`,
      index2: `rotate(-12036deg)`,
      index3: `rotate(-1248deg)`,
      index4: `rotate(-42deg)`,
      index5: `rotate(-6deg)`,
    },
    config: { duration: 4000, easing: easings.easeCubicOut },
    onRest: () => {
      setAnimatingLeft(false);
    },
    delay: 6000,
  });

  const [deg, setDeg] = useState(0);

  const springProps1Slow = useSpring({
    loop: true,
    from: {
      index1: `rotate(0deg)`,
      index2: `rotate(-36deg)`,
      index3: `rotate(-48deg)`,
      index4: `rotate(-42deg)`,
      index5: `rotate(-6deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-840deg)`,
      index2: `rotate(-120deg)`,
      index3: `rotate(-60deg)`,
      index4: `rotate(-48deg)`,
      index5: `rotate(-6deg)`,
    },
    config: { duration: 720000 },
    delay: 10000,
  });

  const springProps2 = useSpring({
    from: {
      index6: `rotate(-6deg)`,
      index7: `rotate(-6deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index6: `rotate(-24deg)`,
      index7: `rotate(-12deg)`,
    },
    config: { duration: 4000, easing: easings.easeCubicOut },
    onRest: () => {
      setAnimatingRight(false);
    },
  });

  const springProps2Slow = useSpring({
    loop: true,
    from: {
      index6: `rotate(-24deg)`,
      index7: `rotate(-12deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index6: `rotate(-84deg)`,
      index7: `rotate(-18deg)`,
    },
    config: { duration: 90000 },
    delay: 4000,
  });

  const settings = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
  };

  // const [trail, api] = useTrail(4, (i) => ({
  //   from: {
  //     transform: "rotate(0deg)"
  //   },
  //   to: { transform: `rotate(30deg)`},
  //   config: {duration: 4000},
  // }));

  return (
    <>
      {/* <Helmet>
        <title>{`Best HR Services in India | Job Consultancy | Recruitment`}</title>
        <meta name="description" content='Credence HR Services is a reputated recruitment firm in India providing best in class HR Services. It is one of the top HR consultancy (ies) in India.' />
        <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="p-0 pt-5 d-flex flex-column"
        fluid
        style={styles.parentContainer}
      >
        {/* <Breakpoint medium down>
        tejas
      </Breakpoint> */}
        {/* <NavBar /> */}
        <Container className="px-lg-5 top-container" fluid>
          <Row>
            <Col lg={6} className="text-center home-col-left py-5">
              <div className="home-stats mt-4 my-xl-5">
                {/* <Button
                onClick={() => {
                  setDeg(6);
                }}
              >
                Click
              </Button> */}
                <div className="d-flex justify-content-center mb-1 bouncing-letter position-relative overflow-hidden">
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index1
                        : springProps1Slow.index1,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index2
                        : springProps1Slow.index2,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index3
                        : springProps1Slow.index3,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff * 2,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index4
                        : springProps1Slow.index4,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff * 3,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: springProps1.index5,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff * 4,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                </div>
              </div>
              <div className="home-stats-caption mt-4">
                Number of candidates <br />
                who we helped
              </div>
            </Col>
            <Col lg={6} className="text-center home-col-right py-5">
              <div className="home-stats mt-4 my-xl-5">
                <div className="d-flex justify-content-center mb-1 bouncing-letter position-relative overflow-hidden">
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingRight
                        ? springProps2.index6
                        : springProps2Slow.index6,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff - 20,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingRight
                        ? springProps2.index7
                        : springProps2Slow.index7,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff * 2 - 20,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                </div>
              </div>
              <div className="home-stats-caption mt-4">
                Clients who trust us with finding <br /> them the right talent
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          className="text-center bottom-container p-4 p-lg-0 d-flex flex-fill flex-column"
          fluid
        >
          <Breakpoint medium down>
            <div className="mt-4 mb-5 d-flex justify-content-center">
              {/* <Link to="/explore"> */}
              <Button
                type="link"
                href="/explore"
                className="btn-main d-flex align-items-center"
              >
                <div>EXPLORE</div> <FiArrowRight className="ms-3" size="18px" />
              </Button>
              {/* </Link> */}
            </div>
            <Row>
              {clients.map((item, index) => {
                return (
                  <Col
                    xs={3}
                    lg={true}
                    key={item.id}
                    className="py-1 px-0 d-flex justify-content-center"
                  >
                    <ClientCard item={item} />
                  </Col>
                );
              })}
            </Row>
          </Breakpoint>
          <Breakpoint large up>
            <Slider as={Row} {...settings} className="overflow-hidden">
              {clients.map((item, index) => {
                return (
                  <Col
                    key={item.id}
                    className="py-5 px-0 d-flex justify-content-center"
                  >
                    <ClientCard item={item} />
                  </Col>
                );
              })}
            </Slider>
            <div className="m-0 d-flex justify-content-center">
              {/* <Link to="/explore"> */}
              <Button
                type="link"
                href="/explore"
                className="btn-main d-flex align-items-center mb-5"
              >
                <div>EXPLORE</div>{" "}
                <FiArrowRight className="ms-3 mb-1" size="18px" />
              </Button>
              {/* </Link> */}
            </div>
          </Breakpoint>
        </Container>
        <Container className="px-lg-5 top-container" fluid>
          <Row>
            <Col lg={7} className="py-5 px-4">
              <h1
                className="my-4 mb-5"
                style={{ fontSize: 24, fontWeight: 600 }}
              >
                About Credence HR Services
              </h1>
              <div className="mt-4" style={{ fontSize: 18, fontWeight: 600 }}>
                Know More About us: Credence HR Services is a HR Consultancy firm
                located in Pune (India). We are one of the leading recruitment
                services company/ recruitment firm based in Pune (India). Our
                established process including our AI tool, allow us to look into
                data across job boards, hiring websites, and job websites and help
                search talent that optimally matches an open role.
              </div>
              <div className="mt-4" style={{ fontSize: 18, fontWeight: 600 }}>
                We are conscious that you have the choice of other HR services, HR
                consultancy and recruitment agencies in India that provide HR
                Solutions. We (Credence HR Services) aspire to be the most admired
                in this space.
              </div>
              <div className="mt-4" style={{ fontSize: 18, fontWeight: 600 }}>
                Our strength lies in helping search talent for Financial Services,
                Banks, BFSI, Information Technology, ITeS, Manufacturing and
                Allied Services. We endeavor to be the preferred HR Services
                partner, placement consultancy/ recruitment agency of choice for
                our clients and candidates.
              </div>
              <div className="mt-4" style={{ fontSize: 18, fontWeight: 600 }}>
                If you are looking for a job or searching for talent, look no
                further. Reach out to Credence HR Services (visit us at
                www.credencehrservices.com)
              </div>
              <div className="d-flex justify-content-center justify-content-md-start">
                <Button
                  type="link"
                  href="/explore"
                  className="btn-main d-flex align-items-center my-5"
                >
                  <div>EXPLORE</div>{" "}
                  <FiArrowRight className="ms-3 mb-1" size="18px" />
                </Button>
              </div>
            </Col>
            <Col
              lg={5}
              className="text-center py-5 d-flex justify-content-center align-items-center"
            >
              <div className="mt-4 my-xl-5 d-flex justify-content-cente align-items-center">
                <Image src="/assets/svgs/explore.svg" alt="Explore our HR Services" className="explore-main-page-image" />
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </Container>
    </>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Home;
