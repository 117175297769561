export function resetAllPages() {
  return {
    type: "RESETALLPAGES",
    data: { currentPage: 1, goToPage: 1 },
  };
}

export function increment() {
  return {
    type: "INCREMENT",
  };
}

export function decrement() {
  return {
    type: "DECREMENT",
  };
}

export function reset() {
  return { type: "RESET" };
}

export function switchToPage(currentPage, goToPage) {
  return {
    type: "SWITCHTOPAGE",
    data: { currentPage: currentPage, goToPage: goToPage },
  };
}

export function increment1() {
  return {
    type: "INCREMENT1",
  };
}

export function decrement1() {
  return {
    type: "DECREMENT1",
  };
}

export function switchToPage1(currentPage, goToPage) {
  return {
    type: "SWITCHTOPAGE1",
    data: { currentPage: currentPage, goToPage: goToPage },
  };
}

export function switchMode(mode) {
  return {
    type: "SWITCHMODE",
    data: { mode: mode },
  };
}
