import React, { useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Button,
  Carousel,
} from "react-bootstrap";
import NavBar from "components/NavBar";
import RecruiterCard from "components/RecruiterCard";
import "./HomeRecruiter.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";
import "components/RecruiterCard/RecruiterCard.scss";

import {
  Breakpoint,
  useCurrentBreakpointName,
  useCurrentWidth,
} from "react-socks";

import { increment, decrement, switchToPage } from "actions";
import "components/RecruiterCard/RecruiterCard.scss";
import ContactForm from "components/ContactForm";
import Footer from "components/Footer";

const client_feedbacks = [
  {
    id: 1,
    text: "Thank you ‘Credence HR Services’ for your continued support & contribution.",
    author_company: "Synchrony International Services",
    author_name: "Vice President - HR",
  },
  {
    id: 2,
    text: "It is a reliable, proactive and efficient HR consultancy. They keep end to end track of their candidates and follow up in a timely manner. I wish them the very best.",
    author_company: "VP at an Investment Bank",
    author_name: null,
  },
  {
    id: 3,
    text: "They are a bunch of well trained & skilled recruiters who consistently support me in my aim to hire the best talent available.",
    author_company: "Teamlead Recruitment ",
    author_name: "A Technology Consulting Firm",
  },
];

const Recruitment1 = React.memo((props) => {
  // const state = useContext(RecruiterContext);
  const dispatch = useDispatch();

  const breakpoint = useCurrentBreakpointName();
  let items = {};
  switch (breakpoint) {
    case "xxlarge":
      items = [
        {
          id: 1,
          img: "/assets/svgs/recruiter/User1.svg",
          alt:"User1",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 2,
          img: "/assets/svgs/recruiter/User2.svg",
          alt:"User2",
          from1: {
            x: 355,
            y: 170,
            zIndex: 1000,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            zIndex: 1000,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
          },
          leave: { opacity: 0 },
        },
        {
          id: 3,
          img: "/assets/svgs/recruiter/User3.svg",
          alt:"User3",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 4,
          img: "/assets/svgs/recruiter/User4.svg",
          alt:"User4",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 5,
          img: "/assets/svgs/recruiter/User5.svg",
          alt:"User5",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 6,
          img: "/assets/svgs/recruiter/User6.svg",
          alt:"User6",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 7,
          img: "/assets/svgs/recruiter/User7.svg",
          alt:"User7",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 8,
          img: "/assets/svgs/recruiter/User8.svg",
          alt:"User8",
          from1: {
            x: -330,
            y: 55,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
            zIndex: 1000,
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
            zIndex: 1000,
          },
          leave: { opacity: 0 },
        },
        {
          id: 9,
          img: "/assets/svgs/recruiter/User1.svg",
          alt:"User1",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 10,
          img: "/assets/svgs/recruiter/User4.svg",
          alt:"User4",
          from1: {
            x: 50,
            y: -60,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
            zIndex: 1000,
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
            zIndex: 1000,
          },
          leave: { opacity: 0 },
        },
        {
          id: 11,
          img: "/assets/svgs/recruiter/User3.svg",
          alt:"User3",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 12,
          img: "/assets/svgs/recruiter/User2.svg",
          alt:"User2",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 13,
          img: "/assets/svgs/recruiter/User5.svg",
          alt:"User5",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 14,
          img: "/assets/svgs/recruiter/User6.svg",
          alt:"User6",
          from1: {
            x: 205,
            y: -185,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
            zIndex: 1000,
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
            zIndex: 1000,
          },
          leave: { opacity: 0 },
        },
        {
          id: 15,
          img: "/assets/svgs/recruiter/User7.svg",
          alt:"User7",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 16,
          img: "/assets/svgs/recruiter/User8.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
      ];
      break;
    case "xlarge":
      items = [
        {
          id: 1,
          img: "/assets/svgs/recruiter/User1.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 2,
          img: "/assets/svgs/recruiter/User2.svg",
          from1: {
            x: 355,
            y: 170,
            zIndex: 1000,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            zIndex: 1000,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
          },
          leave: { opacity: 0 },
        },
        {
          id: 3,
          img: "/assets/svgs/recruiter/User3.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 4,
          img: "/assets/svgs/recruiter/User4.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 5,
          img: "/assets/svgs/recruiter/User5.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 6,
          img: "/assets/svgs/recruiter/User6.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 7,
          img: "/assets/svgs/recruiter/User7.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 8,
          img: "/assets/svgs/recruiter/User8.svg",
          from1: {
            x: -330,
            y: 55,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
            zIndex: 1000,
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
            zIndex: 1000,
          },
          leave: { opacity: 0 },
        },
        {
          id: 9,
          img: "/assets/svgs/recruiter/User1.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 10,
          img: "/assets/svgs/recruiter/User4.svg",
          from1: {
            x: 50,
            y: -60,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
            zIndex: 1000,
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
            zIndex: 1000,
          },
          leave: { opacity: 0 },
        },
        {
          id: 11,
          img: "/assets/svgs/recruiter/User3.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 12,
          img: "/assets/svgs/recruiter/User2.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 13,
          img: "/assets/svgs/recruiter/User5.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 14,
          img: "/assets/svgs/recruiter/User6.svg",
          from1: {
            x: 205,
            y: -185,
            opacity: 1,
            scale: 1.25,
            borderWidth: 2,
            borderColor: "#001e24",
            zIndex: 1000,
          },
          from: { opacity: 1 },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 0,
            borderColor: "#fff",
            zIndex: 1000,
          },
          leave: { opacity: 0 },
        },
        {
          id: 15,
          img: "/assets/svgs/recruiter/User7.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 16,
          img: "/assets/svgs/recruiter/User8.svg",
          from: { opacity: 1 },
          from1: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
      ];
      break;
    default:
      items = [
        {
          id: 1,
          img: "/assets/svgs/recruiter/User1.svg",
          from: { opacity: 1 },
        },
        {
          id: 2,
          img: "/assets/svgs/recruiter/User2.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 3,
          img: "/assets/svgs/recruiter/User3.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 4,
          img: "/assets/svgs/recruiter/User4.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 5,
          img: "/assets/svgs/recruiter/User5.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 6,
          img: "/assets/svgs/recruiter/User6.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 7,
          img: "/assets/svgs/recruiter/User7.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 8,
          img: "/assets/svgs/recruiter/User8.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 9,
          img: "/assets/svgs/recruiter/User1.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 10,
          img: "/assets/svgs/recruiter/User4.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 11,
          img: "/assets/svgs/recruiter/User3.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 12,
          img: "/assets/svgs/recruiter/User2.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 13,
          img: "/assets/svgs/recruiter/User5.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 14,
          img: "/assets/svgs/recruiter/User6.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 15,
          img: "/assets/svgs/recruiter/User7.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
        {
          id: 16,
          img: "/assets/svgs/recruiter/User8.svg",
          initial: { opacity: 0 },
          enter: { opacity: 1, borderWidth: 0 },
          leave: { opacity: 0 },
        },
      ];
  }

  const clients = [
    {
      id: 1,
      img: "/assets/images/clients/fis.png",
      alt:"FIS"
    },
    {
      id: 2,
      img: "/assets/images/clients/northern_trust.png",
      alt:"Northern trust"
    },
    {
      id: 3,
      img: "/assets/images/clients/yash_technologies.png",
      alt:"Yash Tecnology"
    },
    {
      id: 4,
      img: "/assets/images/clients/sopra_steria.png",
      alt: "Sopra Steria"
    },
    {
      id: 5,
      img: "/assets/images/clients/jp_morgan.png",
      alt: "JP Morgan"
    },
    {
      id: 6,
      img: "/assets/images/clients/hsbc.png",
      alt: "HSBC Bank"
    },
    {
      id: 7,
      img: "/assets/images/clients/mandg.png",
      alt: "M&G Investment"
    },
    {
      id: 8,
      img: "/assets/images/clients/fractal.png",
      alt: "Fractal"
    },
    {
      id: 9,
      img: "/assets/images/clients/emc.png",
      alt: "EMC"
    },
    {
      id: 10,
      img: "/assets/images/clients/eclerx.png",
      alt: "eClerx"
    },
    {
      id: 11,
      img: "/assets/images/clients/credit_suisse.png",
      alt: "Credit suisse"
    },
    {
      id: 12,
      img: "/assets/images/clients/deutsche_bank.png",
      alt: "Deutsche bank"
    },
    {
      id: 13,
      img: "/assets/images/clients/berkadia.png",
      alt: "Berkadia"
    },
    {
      id: 14,
      img: "/assets/images/clients/10fa.png",
      alt: "10 FA India Pvt Ltd"
    },
    {
      id: 15,
      img: "/assets/images/clients/something.png",
      alt: "more"
    },
  ];

  const trust_points = [
    {
      id: 1,
      img: "/assets/svgs/recruiter/recruiter3/diversity.png",
      backgroundColor: "#FFEACC",
      text: "Diversity Ratio of candidates sourced stand at 61%",
      alt:'diversity'
    },
    {
      id: 2,
      img: "/assets/svgs/recruiter/recruiter3/top_partners.png",
      backgroundColor: "#FBEFD0",
      text: "Among Top 3 vendor partners for most of our clients",
      alt:'top partner'
    },
    {
      id: 3,
      img: "/assets/svgs/recruiter/recruiter3/join_ratio.png",
      backgroundColor: "#DCEFDD",
      text: "Profile Sourced to Join Ratio at 5:1",
      alt:'Join Ratio'
    },
    {
      id: 4,
      img: "/assets/svgs/recruiter/recruiter3/drop_rate.png",
      backgroundColor: "#E5DFEC",
      text: "Drop Rate lower than 5%",
      alt:'Drop Rate'
    },
    {
      id: 5,
      img: "/assets/svgs/recruiter/recruiter3/diversity_senior.png",
      backgroundColor: "#E1E7EA",
      text: "Diversity joining at senior levels at 72%",
      alt:'Senior Diversity'
    },
  ];

  const count = useSelector((state) => state.counters);
  const contactRef = useRef(null);

  const transitions = useTransition(items, {
    key: (item) => item.key,
    from: (item) =>
      count.recruiterPrevState > count.recruiterCurrentState
        ? item.from1
        : item.from,
    enter: (item) => item.enter,
    leave: (item) => item.leave,
    config: { duration: 1500, tension: 200 },
    onRest: {
      // opacity: () => alert("opacity"),
    },
  });

  const [text, setText] = useState(true);

  const textTransition = useTransition(text, {
    from:
      count.recruiterPrevState > count.recruiterCurrentState
        ? { y: -200, opacity: 0 }
        : { y: 0, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -200, opacity: 0 },
  });

  const arr = [2, 5, 10, 14];
  const [disable, setDisable] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      // setPreviousPage(currentPage);
      // setCurrentPage(currentPage - 1);
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (!scrolled) {
        setDisable(true);
        setText(false);
        dispatch(increment());
      }
      setScrolled(true);
    }
  };

  // const changeCurrentPage = (value) => {
  //   setCurrentPage(value);
  // };

  const width = useCurrentWidth();

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container
          className="p-0 pt-5 d-flex flex-column position-absolute"
          fluid
          style={styles.parentContainer}
          onWheel={handleWheel}
        >
          {/* <NavBar /> */}
          <Container className="p-5" fluid>
            <Row>
              <Col
                lg={6}
                className="d-flex align-items-center justify-content-start px-5"
              >
                <div>
                  {textTransition((props, item) => {
                    if (item) {
                      return (
                        <animated.div
                          className="recruiter-main-sidetext"
                          style={props}
                        >
                          <div>
                            Because it is important to find
                            <br />
                            the right talent for your team
                          </div>
                          <Button
                            type="button"
                            className="mt-5 btn-main "
                            onClick={() => {
                              dispatch(switchToPage(1, 6));
                            }}
                          >
                            <h4>GET IN TOUCH</h4>
                          </Button>
                        </animated.div>
                      );
                    } else {
                      return <div />;
                    }
                  })}
                </div>
              </Col>
              <Col lg={6} className="text-center my-4 py-5 px-5">
                <Row>
                  <Col lg={9} style={{zIndex:1}}>
                    <Row>
                      {/* {items.map((item, index) => {
                    return <RecruiterCard key={item.id} item={item} />;
                  })} */}
                      {transitions((props, item) => {
                        if (item) {
                          return (
                            <Col
                              as={animated.div}
                              lg={3}
                              key={item.id}
                              style={props}
                            >
                              {disable ? (
                                !arr.includes(item.id) && (
                                  <Card
                                    as={animated.div}
                                    className="recruiterCard d-flex justify-content-center align-items-center my-3"
                                    style={{
                                      borderWidth: props.borderWidth,
                                      borderColor: props.borderColor,
                                    }}
                                  >
                                    <Image
                                      className="recruiterCardImg"
                                      src={item.img}
                                      alt={item.alt}
                                      rounded
                                    />
                                  </Card>
                                )
                              ) : (
                                <Card
                                  as={animated.div}
                                  className="recruiterCard d-flex justify-content-center align-items-center my-3"
                                  style={{
                                    borderWidth: props.borderWidth,
                                    borderColor: props.borderColor,
                                  }}
                                >
                                  <Image
                                    className="recruiterCardImg"
                                    src={item.img}
                                    alt={item.alt}
                                    rounded
                                  />
                                </Card>
                              )}
                            </Col>
                          );
                        } else {
                          return <div />;
                        }
                      })}
                    </Row>
                  </Col>
                </Row>
                <div
                  className="d-flex flex-column position-fixed"
                  style={{ right: 50, bottom: 50 }}
                >
                  <Image
                    className="arrow my-2"
                    src="/assets/svgs/recruiter/arrow_up.svg"
                    alt="Arrow up"
                    style={{ opacity: 0.4 }}
                  />
                  <Image
                    className="arrow"
                    src="/assets/svgs/recruiter/arrow_down.svg"
                    alt="Arrow Down"
                    onClick={() => {
                      setDisable(true);
                      setText(false);
                      dispatch(increment());
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container fluid className="mt-5 pt-3">
          <Row>
            <div
              className="p-4 my-4 my-md-5 text-center"
              style={{ color: "#001E24", fontSize: 32, fontWeight: 600 }}
            >
              Because recruitment
              <br className="d-block d-md-none" /> is Critical. <br /> <br />
              We help you get
              <br className="d-block d-md-none" /> the match right
            </div>
            <div className="text-center">
              <Button
                className="btn-main"
                onClick={() => {
                  contactRef.current.scrollIntoView();
                }}
              >
                GET IN TOUCH
              </Button>
            </div>
            <div className="d-flex justify-content-center align-items-end mt-5 p-0">
              <Image
                src="/assets/svgs/recruiter/recruiter-mobile.png"
                alt="recruiter mobile"
                style={{ width: "100%" }}
              />
            </div>
          </Row>
          <Row style={{ backgroundColor: "#02493C" }}>
            <div
              className="p-4 my-4 text-white text-left text-md-center"
              style={{ fontSize: 24, fontWeight: 600 }}
            >
              Our clients continue to show Trust and Faith in us. We have
              Partnered with our clients to help them choose over 15225
              candidates till date
            </div>
            <Row className="p-4 my-4">
              {clients.map((item, index) => {
                return (
                  <Col xs={item.id == 15 ? 6 : 3} key={item.id}>
                    <Card
                      as={animated.div}
                      className={`d-flex justify-content-center align-items-center my-3 ${item.id == 15
                        ? "recruiterCardClientLast"
                        : "recruiterCardClient"
                        }`}
                      style={props}
                    >
                      {item.id == 15 && (
                        <p className="and_more_text">And More...</p>
                      )}
                      {item.id != 15 && (
                        <Image
                          className="recruiterCardClientImg"
                          src={item.img}
                          alt={item.alt}
                          rounded
                        />
                      )}
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Row>
          <Row>
            <div
              className="p-4 my-4 text-left text-md-center"
              style={{ color: "#001E24", fontSize: 20, fontWeight: 600 }}
            >
              Over the past years*, the trust and encouragement from our clients
              have helped us achieve noteworthy milestones.
            </div>
            <Row>
              {trust_points.map((item, index) => {
                return (
                  <div className="d-flex align-items-center m-4">
                    <div className="align-items-center">
                      <Card
                        style={{ backgroundColor: item.backgroundColor }}
                        className="border-0 recruiter3Card justify-content-center align-items-center"
                      >
                        <Image className="recruiter3CardImg" src={item.img} alt={item.alt}/>
                      </Card>
                    </div>
                    <div className="flex-grow-1 ms-5 py-0 m-0">{item.text}</div>
                  </div>
                );
              })}
            </Row>
            <Row>
              <Col
                lg={12}
                className="text-center mt-5"
                style={{ fontSize: 12 }}
              >
                *Average figures over past 24 month
              </Col>
            </Row>
          </Row>
          <Row className="testimonial-bg py-5">
            <h4
              className="text-left text-md-center"
              style={{ marginTop: "110px" }}
            >
              What our client says
            </h4>
            <Carousel
              indicators={true}
              prevLabel={null}
              nextLabel={null}
              prevIcon={null}
              nextIcon={null}
              fade
              variant="dark"
            >
              {client_feedbacks.map((item, index) => {
                return (
                  <Carousel.Item
                    key={item.id}
                    className="text-start p-2 py-5 p-md-5 d-flex align-items-center justify-content-center"
                  >
                    <div className="client-opinions-carousel-box">
                      <div className="client-opinions-carousel py-4 px-4 h-100">
                        <div className="d-flex flex-column justify-content-center align-items-center h-75 user-select-none">
                          {item.text}
                        </div>
                        <div className="d-flex align-self-center justify-content-center client-card-footer px-2 text-center">
                          <p
                            className="px-2 py-1"
                            style={{ borderTop: "2px solid black" }}
                          >
                            <span style={{ fontWeight: 600 }}>
                              {item.author_company}
                            </span>
                            <br />
                            <span>{item.author_name}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Row>
          <Container className="p-0 pt-3 mt-5" fluid ref={contactRef}>
            <Container
              className={`d-flex flex-column p-0 ${width >= 992 ? "bg-get-in-touch" : ""
                }`}
              fluid
            >
              <ContactForm pageType="aboutUs" page="Recruitment" />
            </Container>
          </Container>

          <Container className="p-0" fluid>
            <Footer />
          </Container>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
});

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Recruitment1;
