import React, { useContext } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavBar from "components/NavBar";
import RecruiterCard from "components/RecruiterCard";
import "./HomeRecruiter.scss";
import ContactForm from "components/ContactForm";
import { RecruiterContext } from "navigation/HomeNavigation/HomeRecruiterNavigation/RecruiterContext";
import { useDispatch } from "react-redux";
import { increment, decrement } from "actions";

const items = [
  { id: 1, img: "assets/svgs/recruiter/User1.svg" },
  { id: 2, img: "assets/svgs/recruiter/User2.svg" },
  { id: 3, img: "assets/svgs/recruiter/User3.svg" },
  { id: 4, img: "assets/svgs/recruiter/User4.svg" },
  { id: 5, img: "assets/svgs/recruiter/User5.svg" },
  { id: 6, img: "assets/svgs/recruiter/User6.svg" },
  { id: 7, img: "assets/svgs/recruiter/User7.svg" },
  { id: 8, img: "assets/svgs/recruiter/User8.svg" },
  { id: 9, img: "assets/svgs/recruiter/User1.svg" },
  { id: 10, img: "assets/svgs/recruiter/User4.svg" },
  { id: 11, img: "assets/svgs/recruiter/User3.svg" },
  { id: 12, img: "assets/svgs/recruiter/User2.svg" },
  { id: 13, img: "assets/svgs/recruiter/User5.svg" },
  { id: 14, img: "assets/svgs/recruiter/User6.svg" },
  { id: 15, img: "assets/svgs/recruiter/User7.svg" },
  { id: 16, img: "assets/svgs/recruiter/User8.svg" },
];

const Recruitment6 = () => {
  const dispatch = useDispatch();

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      dispatch(decrement());
    }
    if (event.nativeEvent.wheelDelta < -50) {
    }
  };

  return (
    <Container
      className="p-0 pt-5 bg-get-in-touch position-absolute"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container className="px-5 d-flex flex-column" fluid>
        <ContactForm page="Recruitment"/>
        <div
          className="d-flex flex-column position-fixed"
          style={{ right: 50, bottom: 50 }}
        >
          <Image
            className="arrow my-2"
            src="/assets/svgs/get-in-touch/arrow_up.svg"
            alt="Arrow up"
            onClick={() => {
              dispatch(decrement());
            }}
          />
          <Image
            className="arrow"
            src="/assets/svgs/get-in-touch/arrow_down.svg"
            alt="Arrow Down"
            style={{opacity: 0.4}}
          />
        </div>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    backgroundColor: "#02493C",
    minHeight:100+'vh'
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Recruitment6;
