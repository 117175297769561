import React, { useState, useContext, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { increment, decrement, reset } from "actions";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
} from "react-router-dom";
import { useTransition, animated } from "react-spring";
import Recruitment1 from "screens/Home/HomeRecruiter/Recruitment1";
import Recruitment2 from "screens/Home/HomeRecruiter/Recruitment2";
import Recruitment3 from "screens/Home/HomeRecruiter/Recruitment3";
import Recruitment4 from "screens/Home/HomeRecruiter/Recruitment4";
import Recruitment5 from "screens/Home/HomeRecruiter/Recruitment5";
import Recruitment6 from "screens/Home/HomeRecruiter/Recruitment6";
import { RecruiterContext, RecruiterProvider } from "./RecruiterContext";
import { Container } from "react-bootstrap";

const arr = [
  {
    key: 1,
    state: true,
    element: <Recruitment1 />,
  },
  {
    key: 2,
    state: false,
    element: <Recruitment2 />,
  },
  {
    id: 3,
    element: <Recruitment3 />,
  },
  {
    id: 4,
    element: <Recruitment4 />,
  },
  {
    id: 5,
    element: <Recruitment5 />,
  },
  {
    id: 6,
    element: <Recruitment6 />,
  },
];

const HomeRecruiterNavigation = (props) => {
  // let { path, url } = useRouteMatch();
  // let urlparams = useParams();
  // console.log(path, url, urlparams);

  // const location = useLocation();
  // console.log(location);

  const count = useSelector((state) => state.counters);

  const [items, setItems] = useState(arr);
  const element = items[count.recruiterCurrentState - 1];

  const transitions = useTransition(element, {
    key: (item) => item.key,
    initial: { life: "0%", opacity: 0, height: 0 },
    enter: (item) => [{ opacity: 1, height: "100%" }, { life: "100%" }],
    leave: (item) => async (next, cancel) => {
      await next({ life: "0%" });
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    from: { life: "0%", opacity: 1, height: 0 },
    config: { duration: 1000 },
  });

  console.log(count);

  return (
    <Container fluid className="p-0">
      {transitions((props, item) => {
        console.log(item);
        return (
          <animated.div style={props} fluid>
            {item ? item.element : <div />}
          </animated.div>
        );
      })}
    </Container>
  );
};

export default HomeRecruiterNavigation;
