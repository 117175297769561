import React, { useRef, useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import NavBar from "components/NavBar";
import "./AboutUs.scss";
import ContactForm from "components/ContactForm";
import { useSpring, animated } from "react-spring";
import Footer from "components/Footer";
import { Breakpoint, useCurrentWidth } from "react-socks";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const contactRef = useRef(null);
  const [isLeftAniDone, setIsLeftAniDone] = useState(false);
  const [isRightAniDone, setIsRightAniDone] = useState(false);

  const [springProps, leftapi] = useSpring(() => ({ opacity: 0, x: -300 }));

  const [springProps1, rightapi] = useSpring(() => ({ opacity: 0, x: 300 }));

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 300 && !isLeftAniDone) {
        leftapi.start({ opacity: 1, x: 0 });
        setIsLeftAniDone(true);
      }

      if (scrolled >= 900 && !isRightAniDone) {
        rightapi.start({ opacity: 1, x: 0 });
        setIsRightAniDone(true);
      }
    });

    return () => document.removeEventListener("scroll", () => { });
  }, []);

  const width = useCurrentWidth()

  const location = useLocation();

  return (
    <>
      {/* <Helmet>
        <title>{`Job Consultancy|HR Services Company|Recruitment consultants`}</title>
        <meta name="description" content='We are one of the best HR outsourcing companies. We help you get jobs in IT and in BFSI with large technology and banking services companies.' />
        <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
      </Helmet> */}
      <Container
        className="p-0 pt-5 d-flex flex-column"
        fluid
        style={styles.parentContainer}
      >
        {/* <NavBar /> */}
        <Container fluid>
          <Row>
            <Col
              lg={12}
              className="d-flex flex-column align-items-center justify-content-center text-white background-about-us py-2 py-lg-5"
            >
              <h1 className="d-none">ABOUT US</h1>
              <div className="about-us-main-text mb-3 px-3 px-lg-5 py-4 my-4 py-lg-5 my-lg-5">
                We work towards putting smiles on faces. Our passion is the
                cornerstone of our success. We believe that together we can go
                further and therefore collaboration is central to our delivery.
                The usuals like cost effectiveness, confidentiality, integrity,
                client focus, etc. are all foregone in our dealings. We do all
                these and more for you- whether you are looking to hire or looking
                to get hired. We are driven by two main philosophies: know more
                today about the world of human resources than we knew yesterday
                and more tomorrow than we did today. Coupled with this is our
                ability to convert ideas into delivery makes our model a force to
                reckon with. We therefore, provide solutions and not just service.
              </div>
              <Button
                className="my-4 btn-light btn-main-white"
                onClick={() => {
                  contactRef.current.scrollIntoView();
                }}
              >
                CONTACT US
              </Button>
            </Col>
          </Row>
          <Breakpoint large up>
            <Row className="my-5 overflow-hidden">
              <Col
                as={animated.div}
                lg={6}
                className="d-flex align-items-center justify-content-center"
                style={springProps}
              >
                <h2 className="d-none">Our Vision</h2>
                <Image
                  src="/assets/svgs/aboutus/vision.svg"
                  className="vision_left"
                  alt="vision"
                />
              </Col>
              <Col
                lg={6}
                className="d-flex align-items-center background-about-us-vision ps-5"
              >
              <h3 className="d-none">Mission</h3>
                <div className="justify-content-center ps-5 about-us-description-text">
                  We will work towards being one of <br />
                  the most admired organisations in <br />
                  our line of business by all whose <br />
                  lives we touch and put smiles on <br />
                  their faces.
                </div>
              </Col>
            </Row>
            <Row className="overflow-hidden">
              <Col
                lg={6}
                className="d-flex align-items-center justify-content-center background-about-us-mission px-2"
              >
                <div className="about-us-description-text">
                  To know more today about the world of <br />
                  human resources than we knew yesterday <br />
                  and more tomorrow than we did today. Put <br />
                  that knowledge to use by application of <br />
                  intelligent technology to make talent <br /> acquisition a
                  wholesome experience.
                </div>
              </Col>
              <Col
                as={animated.div}
                lg={6}
                className="d-flex align-items-center justify-content-center"
                style={springProps1}
              >
                <Image
                  src="/assets/svgs/aboutus/mission.svg"
                  className="vision_left"
                  alt="mission"
                />
              </Col>
            </Row>
          </Breakpoint>
          <Container
            className="p-0 pt-3 mt-3 mt-lg-5"
            fluid
            style={{ ...styles.parentContainer }}
            ref={contactRef}
          >
            <Container className={`d-flex flex-column p-0 ${width >= 992 ? 'bg-get-in-touch' : ''}`} fluid>
              <ContactForm pageType="aboutUs" is_light={true} page="About Us" />
            </Container>
          </Container>
          <Container className="p-0" fluid>
            <Footer />
          </Container>
        </Container>
      </Container>
    </>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default AboutUs;
