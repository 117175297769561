import React from "react";
import { Container, Modal, Button, Image } from "react-bootstrap";

const ThankYou = ({ visible, setVisible }) => {
  return (
    <Container
      fluid
      className={`${
        visible ? "d-flex" : "d-none"
      } position-fixed fixed-top w-100 d-flex justify-content-center align-items-center h-100`}
      style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
    >
      <div
        className={`thank-you-background justify-content-center`}
        style={{ borderRadius: 40 }}
        centered
      >
        <div className="w-100">
          <div className="d-flex justify-content-end p-4">
            <Image
              className="cursor-pointer"
              src="/assets/landing/close-button.png"
              onClick={() => {
                setVisible(false);
              }}
            />
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center mx-4">
            <Image
              src={`/assets/landing/thank_you.gif`}
              style={{ width: 180 }}
            />
            <p className="text-center" style={{ fontSize: 16 }}>
              Resume has been sent!
              <br />
              We will get back to you shortly!
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ThankYou;
