import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import NavBar from "components/NavBar";
import RecruiterCard from "components/RecruiterCard";
import "./HomeRecruiter.scss";
import { RecruiterContext } from "navigation/HomeNavigation/HomeRecruiterNavigation/RecruiterContext";
import { useDispatch, useSelector } from "react-redux";
import { increment, decrement, switchToPage } from "actions";
import { useTransition, animated } from "react-spring";
import "components/RecruiterCard/RecruiterCard.scss";

const items = [
  {
    id: 1,
    img: "/assets/svgs/recruiter/recruiter3/diversity.png",
    backgroundColor: "#FFEACC",
    from: {
      opacity: 1,
      x: 730,
      y: -40,
      scale: 0.8,
      op: 0,
      backgroundColor: "#FFF",
    },
    // enter: { opacity: 1, x: 730, y: -40, scale: 0.8 },
    from1: {
      x: 260,
      y: -6,
      scaleY: 2.63,
      scaleX: 4,
      backgroundColor: "#FFF",
    },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      backgroundColor: "#FFEACC",
      scale: 1,
      scaleX: 1,
      scaleY: 1,
      op: 1,
    },
    leave: { opacity: 0 },
    text: "Diversity Ratio of<br/> candidates sourced<br/> stand at 61%",
  },
  {
    id: 2,
    img: "/assets/svgs/recruiter/recruiter3/top_partners.png",
    backgroundColor: "#FBEFD0",
    from: {
      opacity: 1,
      x: 575,
      y: -40,
      scale: 0.8,
      op: 0,
      backgroundColor: "#FFF",
    },
    from1: {
      x: 455,
      y: -6,
      scaleY: 2.63,
      scaleX: 4,
      backgroundColor: "#FFF",
    },
    // enter: { opacity: 1, x: 575, y: -40, scale: 0.8 },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      backgroundColor: "#FBEFD0",
      scale: 1,
      scaleX: 1,
      scaleY: 1,
      op: 1,
    },
    leave: { opacity: 0 },
    text: "Among Top 3 vendor<br /> partners for most of<br /> our clients",
  },
  {
    id: 3,
    img: "/assets/svgs/recruiter/recruiter3/join_ratio.png",
    backgroundColor: "#DCEFDD",
    from: {
      opacity: 1,
      x: 425,
      y: -40,
      scale: 0.8,
      op: 0,
      backgroundColor: "#FFF",
    },
    from1: {
      x: 646,
      y: -6,
      scaleY: 2.63,
      scaleX: 4,
      backgroundColor: "#FFF",
    },
    // enter: { opacity: 1, x: 425, y: -40, scale: 0.8 },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      backgroundColor: "#DCEFDD",
      scale: 1,
      scaleX: 1,
      scaleY: 1,
      op: 1,
    },
    leave: { opacity: 0 },
    text: "Profile Sourced to<br /> Join Ratio at 5:1",
  },
  {
    id: 4,
    img: "/assets/svgs/recruiter/recruiter3/drop_rate.png",
    backgroundColor: "#E5DFEC",
    from: {
      opacity: 1,
      x: 275,
      y: -40,
      scale: 0.8,
      backgroundColor: "#FFF",
      op: 0,
    },
    // enter: { opacity: 1, x: 275, y: -40, scale: 0.8 },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      backgroundColor: "#E5DFEC",
      scale: 1,
      op: 1,
    },
    leave: { opacity: 0 },
    text: "Drop Rate<br /> lower than 5%",
  },
  {
    id: 5,
    img: "/assets/svgs/recruiter/recruiter3/diversity_senior.png",
    backgroundColor: "#E1E7EA",
    from: {
      opacity: 1,
      x: 10,
      y: 75,
      scale: 0.8,
      backgroundColor: "#FFF",
      op: 0,
    },
    // enter: { opacity: 1, x: 10, y: 75, scale: 0.8 },
    enter: {
      opacity: 1,
      x: 0,
      y: 0,
      backgroundColor: "#E1E7EA",
      scale: 1,
      op: 1,
    },
    leave: { opacity: 0 },
    text: "Diversity joining<br /> at senior levels<br /> at 72%",
  },
];

const Recruitment4 = () => {
  const dispatch = useDispatch();

  let scrolled = false;

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        dispatch(decrement());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (scrolled) {
        dispatch(increment());
      }
    }
  };

  const count = useSelector((state) => state.counters);

  const transitions = useTransition(items, {
    key: (item) => item.key,
    from: (item) =>
      count.recruiterPrevState > count.recruiterCurrentState
        ? item.from1
        : item.from,
    enter: (item) => item.enter,
    leave: (item) => item.leave,
    config: { duration: 1000, tension: 200 },
    onRest: () => {
      scrolled = true;
    },
  });

  const [text, setText] = useState(true);

  const textTransition = useTransition(text, {
    from:
      count.recruiterPrevState > count.recruiterCurrentState
        ? { y: 0, opacity: 0 }
        : { x: 0, y: 200, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: { x: 0, y: 200, opacity: 0 },
    config: { duration: 1000, tenstion: 200 },
  });

  return (
    <Container
      className="p-0 pt-5 d-flex flex-column position-absolute"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container className="px-5" fluid>
        <Row>
          <Col
            lg={12}
            className="text-center d-flex align-items-center justify-content-center mt-5 py-2"
          >
            {textTransition((props, item) =>
              item ? (
                <animated.div
                  className="recruiter-main-sidetext my-2"
                  style={{ ...props, fontWeight: 600 }}
                >
                  Over the past years*, the trust and encouragement from our
                  <br /> clients have helped us achieve noteworthy milestones.
                </animated.div>
              ) : (
                <div />
              )
            )}
          </Col>
          <Col lg={12} className="my-4">
            <Row className="mt-5 px-4">
              {transitions((props, item) => {
                if (item) {
                  return (
                    <Col
                      as={animated.div}
                      className="d-flex flex-column align-items-center position-relative"
                    >
                      <Card
                        as={animated.div}
                        className="recruiter3Card d-flex justify-content-center align-items-center border-0"
                        style={{
                          background: "none",
                          boxShadow: "none",
                          zIndex: 3,
                          opacity: props.op,
                        }}
                      >
                        <Image className="recruiter3CardImg" src={item.img} alt="recruiter-card"/>
                      </Card>
                      <Card
                        as={animated.div}
                        className="recruiter3Card d-flex justify-content-center align-items-center border-0 position-absolute"
                        style={props}
                      ></Card>
                      <animated.div
                        className="text-center recruiter3CardText my-3"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                        style={{ opacity: props.op }}
                      ></animated.div>
                    </Col>
                  );
                } else {
                  return <div />;
                }
              })}
            </Row>
          </Col>
          <Col lg={12} className="text-center">
            <Button
              type="button"
              className="mt-3 btn-main align-items-center"
              onClick={() => {
                dispatch(switchToPage(4, 6));
              }}
            >
              <div>START WORKING WITH US</div>{" "}
            </Button>
            <div
              className="d-flex flex-column position-fixed"
              style={{ right: 50, bottom: 50 }}
            >
              <Image
                className="arrow my-2"
                src="/assets/svgs/recruiter/arrow_up.svg"
                alt="Arrow up"
                onClick={() => {
                  dispatch(decrement());
                }}
              />
              <Image
                className="arrow"
                src="/assets/svgs/recruiter/arrow_down.svg"
                alt="Arrow Down"
                onClick={() => {
                  dispatch(increment());
                }}
              />
            </div>
          </Col>
          <Col lg={12} className="text-center my-5" style={{fontSize: 12}}>*Average figures over past 24 month</Col>
        </Row>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Recruitment4;
