import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Spinner,
  Form,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import Amplify, { API } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import { switchMode, switchToPage, switchToPage1 } from "actions";
import "./Footer.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";

const Footer = (props) => {
  const is_dark = true;

  const dispatch = useDispatch();
  const count = useSelector((state) => state.counters);
  console.log(count)


  const location = useLocation();
  console.log(location);

  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState("");

  const sendEmail = async (e) => {
    setIsSending(true);
    e.preventDefault();

    const apiName = "contactus";
    const path = "/contactus";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        // OPTIONAL
        name: email,
        mobile: "",
        email: email,
        page: "Footer",
        company_name: "",
        message: "",
        save_in_spreadsheet: true,
      },
    };
    const notify = (msg) => toast.success(msg);

    // API.post(apiName, path, myInit)
    //   .then((response) => {
    //     if (response.data.success) {
    //       console.log(response);
    //       setIsSending(false);
    //       notify("Thank you for submitting response. We'll contact you soon! ");
    //       setEmail("");
    //     }
    //   })
    //   .catch((error) => {
    //     setIsSending(false);
    //     toast.error("Some Error Occurred");
    //     console.log(error);
    //   });

    try {
      const response = await axios.post("contactus/", {
        name: email,
        mobile: "",
        email: email,
        page: "Footer",
        company_name: "",
        message: "",
        save_in_spreadsheet: true
      });
      if (response.status == 200) {
        console.log(response);
        setIsSending(false);
        notify("Thank you for submitting response. We'll contact you soon! ");
        setEmail("");
      }
    }
    catch (error) {
      setIsSending(false);
      toast.error("Some Error Occurred");
      console.log(error);
    }
  };


  return (
    <Row className="p-5 pt-5" style={styles.parentContainer}>
      <ToastContainer />
      <Col lg={4}>
        <h5 className="footer-headers">Stay Connected</h5>
        <Form className="d-flex flex-column flex-lg-row" onSubmit={sendEmail}>
          <Col lg={5} sm={12}>
            <div className="form-floating">
              <input
                type="email"
                className="form-control input-box px-1"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ backgroundColor: "#F5FFFA" }}
                required
              />
              <label className="ps-0 mb-3">Your&nbsp;email&nbsp;address</label>
            </div>
          </Col>
          <Col lg={6} sm={12} className="my-4 mx-2 my-lg-auto d-none d-lg-flex">
            <Button
              type={isSending ? "button" : "submit"}
              className="btn-main ms-2"
              style={{ borderRadius: 10 + 'px' }}
            >
              {isSending ? (
                <span>
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  SENDING ...
                </span>
              ) : (
                "GET IN TOUCH"
              )}
            </Button>
          </Col>
          <Col lg={6} sm={12} className="my-4 d-lg-none">
            <Button
              type={isSending ? "button" : "submit"}
              variant="primary-outline" className="btn-main-outline py-2"
              style={{ borderRadius: 10 + 'px' }}
            >
              {isSending ? (
                <span>
                  <Spinner
                    className="me-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  SENDING ...
                </span>
              ) : (
                "GET IN TOUCH"
              )}
            </Button>
          </Col>
        </Form>
        <div className="mt-2 py-2 d-none d-lg-flex">
          <a className="mx-2" target="_blank" href="https://www.linkedin.com/company/credence-hr-services/">
            <Image
              className="social-icons"
              src={`/assets/svgs/get-in-touch/linkedin_icon${is_dark ? "_dark" : ""
                }.svg`}
              alt="linkedin icon"
              style={{ cursor: "pointer" }}
            />
          </a>
          <a className="mx-4" target="_blank" href="https://www.facebook.com/Credencehrservices">
            <Image
              className="social-icons"
              alt="facebook icon"
              src={`/assets/svgs/get-in-touch/facebook_icon${is_dark ? "_dark" : ""
                }.svg`}
              style={{ cursor: "pointer" }}
            />
          </a>
          <a target="_blank" href="https://www.instagram.com/credencehr/">
            <Image
              className="mx-2 social-icons"
              src={`/assets/svgs/get-in-touch/instagram_icon${is_dark ? "_dark" : ""
                }.svg`}
              style={{ cursor: "pointer" }}
              alt="facebook icon"
            />
          </a>
        </div>
      </Col>
      <Col lg={2}>
        <h5 className="footer-headers">More</h5>
        <p>
          <a href="/blogs" className="footer-info footer-link">
            Blogs
          </a>
        </p>
        <p>
          <a href="/landing/recruitment-specialist" className="footer-info footer-link">
            Career
          </a>
        </p>
        <p>
          <a href="/get-in-touch" className="footer-info footer-link">
            Contact Us
          </a>
        </p>
      </Col>
      <Col lg={2}>
        <h5 className="footer-headers">Company</h5>
        <p>
          <a href="/about-us" className="footer-info footer-link">
            About Us
          </a>
        </p>
        <p>
          <a href="/ceo-corner" className="footer-info footer-link">
            CEO Corner
          </a>
        </p>
        <p>
          <a href="/home" className="footer-info footer-link" onClick={() => {
            dispatch(switchMode("recruiter"));
            dispatch(switchToPage(1, 1));
          }}>
            I Am Recruiter
          </a>
        </p>
        <p>
          <a href="/home" className="footer-info footer-link"
            onClick={() => {
              console.log(count)
              dispatch(switchMode("candidate"));
              dispatch(switchToPage1(1, 1));
              console.log(count)
            }}>
            I Am Candidate
          </a>
        </p>
      </Col>
      <Col lg={4} className="mt-4 m-lg-0">
        <h5 className="footer-headers">Address</h5>
        <p className="footer-info">
          AWFIS, GK Mall, 3rd Floor, Pimple Saudagar, Pune-411027
        </p>
        {/* <h5 className="footer-headers">Phone no</h5>
        <p className="footer-info">1800 266 4020</p> */}
        <h5 className="footer-headers">Email</h5>
        <a href="mailto:credence@credencehrservices.com" className="footer-info text-decoration-none text-black">credence@credencehrservices.com</a>
        <div className="mt-5 py-2 d-lg-none">
          <a target="_blank" href="https://www.linkedin.com/company/credence-hr-services/">
            <Image
              className="mx-2 social-icons"
              src={`/assets/svgs/get-in-touch/linkedin_icon${is_dark ? "_dark" : ""
                }.svg`}
              style={{ cursor: "pointer" }}
              alt="linkedin icon"
            />
          </a>
          <a target="_blank" href="https://www.facebook.com/Credencehrservices">
            <Image
              className="mx-2 social-icons"
              src={`/assets/svgs/get-in-touch/facebook_icon${is_dark ? "_dark" : ""
                }.svg`}
              style={{ cursor: "pointer" }}
              alt="facebook icon"
            />
          </a>
          <a target="_blank" href="https://www.instagram.com/credencehr/">
            <Image
              className="mx-2 social-icons"
              src={`/assets/svgs/get-in-touch/instagram_icon${is_dark ? "_dark" : ""
                }.svg`}
              style={{ cursor: "pointer" }}
              alt="facebook icon"
            />
          </a>
        </div>
      </Col>
    </Row>
  );
};

const styles = {
  parentContainer: {
    backgroundColor: "#F5FFFA",
    width: 100 + '%',
    marginRight: 0 + 'px !important'
  },
};

export default Footer;
