import ResumeForm from "components/ContactForm/ResumeForm";
import ThankYou from "components/ThankYou";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Landing.scss";
import axios from "axios";
import Footer from "components/Footer";
import { Breakpoint } from "react-socks";


const JobPost = () => {
  const { slug } = useParams();
  const [jobpost, setJobpost] = useState(null);
  const [visible, setVisible] = useState(false);
  const doStuff = async () => {
    const response = await axios.get(`jobpost/${slug}`);
    if (response.status == 200) {
      setJobpost(response.data);
    }
  };

  useEffect(() => doStuff(), []);

  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-start justify-content-md-center align-items-center career-contact px-2 px-md-4 mt-5 mt-md-0 pt-5 pt-md-0"
        style={styles.parentContainer}
      >
        <Row className="w-100 mx-md-4 mx-2 mt-5">
          <Col lg={7}>
            <Row className="my-4 py-2">
              <Col sm={4}>
                <div style={styles.headerTitle}>Job Role</div>
                <div style={styles.title}>{jobpost?.job_role}</div>
              </Col>
              <Col sm={4}>
                <div style={styles.headerTitle}>Years of Experience</div>
                <div style={styles.title}>{jobpost?.years_of_experience}</div>
              </Col>
              <Col sm={4}>
                <div style={styles.headerTitle}>Location</div>
                <div style={styles.title}>{jobpost?.location}</div>
              </Col>
            </Row>
            <Row className="my-4 py-2">
              <Col sm={12}>
                <div style={styles.headerTitle}>Who are we looking for -</div>
                <div style={styles.title}>
                  <div
                    dangerouslySetInnerHTML={{ __html: jobpost?.looking_for }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="my-4 py-2">
              <Col sm={12}>
                <div style={styles.headerTitle}>
                  Roles and Responsibilites -
                </div>
                <div style={styles.title}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: jobpost?.roles_and_responsibilities,
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div style={styles.headerTitle}>You Offer -</div>
                <div style={styles.title}>
                  <div dangerouslySetInnerHTML={{ __html: jobpost?.offer }} />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={5} className="my-2">
            <ResumeForm visible={visible} setVisible={(x) => setVisible(x)} />
          </Col>
        </Row>
        <ThankYou visible={visible} setVisible={(x) => setVisible(x)} />
      </Container>
      <Container className="p-0" fluid>
        <Footer />
      </Container>
    </>
  );
};

const styles = {
  parentContainer: {
    minHeight: "100vh",
    fontFamily: "Montserrat Regular",
  },
  headerTitle: {
    fontSize: 14,
    fontFamily: "Montserrat Regular",
    fontWeight: 600,
  },
  title: {
    fontSize: 16,
    fontFamily: "Montserrat Regular",
    fontWeight: 300,
  },
};

export default JobPost;
