import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import "./ContactForm.scss";

import Amplify, { API } from "aws-amplify";
import { Breakpoint } from "react-socks";
import { ToastContainer, toast } from "react-toastify";
import Dropzone, { useDropzone } from "react-dropzone";
import "react-toastify/dist/ReactToastify.css";
import "./ContactForm.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useRef } from "react";

const ResumeForm = (props) => {
  // Amplify.configure({
  //   API: {
  //     endpoints: [
  //       {
  //         name: "credenceContactUs",
  //         endpoint: "https://dswrvax32k.execute-api.ap-southeast-1.amazonaws.com/dev",
  //         custom_header: async () => {
  //           return { Authorization: 'token' }
  //           // Alternatively, with Cognito User Pools use this:
  //           // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
  //           // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
  //         }
  //       }
  //     ]
  //   }
  // });
  const { slug } = useParams();

  const fileUploadRef = useRef(null)

  const { pageType, is_light, page, visible, setVisible } = props;
  const is_dark = pageType == "aboutUs";

  const [resume, setResume] = useState(null);

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [save_in_spreadsheet, setSaveInSpreadsheet] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const formRef = useRef();

  const notify = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      case "warning":
        toast.warning(msg);
        break;
      default:
        toast.error(msg);
    }
  };

  const submitResume = async (e) => {

    setIsSending(true);
    e.preventDefault();
    if (resume?.length > 0) {
      const formData = new FormData(formRef.current);
      formData.append("resume_file", resume[0]);
      const response = await axios.post(`jobpost/${slug}/submit_resume/`, formData);
      if (response.status === 200) {
        // notify("Thank you for submitting your resume. We'll contact you soon! ", "success");
        setVisible(true);
        setName("");
        setEmail("");
        setMobile("");
        setResume(null);
        setUploaded(false);
        setIsSending(false);
      } else {
        notify("Some error occurred!", "error")
        setIsSending(false);
      }
    } else {
      notify("Please submit your resume!", "error");
    }
    setIsSending(false);


    // const apiName = "contactus";
    // const path = "/contactus";
    // const myInit = {
    //   // OPTIONAL
    //   headers: {}, // OPTIONAL
    //   response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    //   body: {
    //     // OPTIONAL
    //     name: name,
    //     mobile: mobile,
    //     email: email,
    //     page: page,
    //     company_name: company_name,
    //     message: message,
    //     save_in_spreadsheet: true,
    //   },
    // };

    // API.post(apiName, path, myInit)
    //   .then((response) => {
    //     if (response.data.success) {
    //       console.log(response);
    //       setIsSending(false);
    //       notify("Thank you for submitting response. We'll contact you soon! ");
    //       setName("");
    //       setMobile("");
    //       setEmail("");
    //       setCompanyName("");
    //       setMessage("");
    //     }
    //   })
    //   .catch((error) => {
    //     setIsSending(false);
    //     console.log(error);
    //   });
  };

  const openFilePicker = () => {
    fileUploadRef.current.click()
  }

  const handleFileSelect = (e) => {
    console.log(e.target.files);
    let acceptedFile = e.target.files
    console.log("Accepted Files", acceptedFile);
    if (acceptedFile.length > 0) {
      setResume(acceptedFile);
      console.log(acceptedFile);
      setUploaded(true);
    } else {
      notify("Only pdf and word document formats are allowed", "warning");
    }
  }

  return (
    <Container fluid>
      {/* <Breakpoint small up> */}
      <Row>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
        <Container
          fluid
          className={`my-0 px-0 px-md-4 py-3 pt-4`}
          // style={{ backgroundColor: is_light ? "" : "#06575b" }}
          style={styles.contactFormContainer}
        >
          <Form
            className={`bg-white px-4 py-5 resume-upload-form`}
            onSubmit={submitResume}
            style={{ fontFamily: "Montserrat Regular" }}
            ref={formRef}
          >
            <div className="form-floating">
              <input
                type="text"
                className="form-control input-box px-1"
                id="floatingName"
                name="name"
                value={name}
                placeholder="name@example.com"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label className="ps-0 mb-3" style={styles.resumeFormText}>
                Your Name
              </label>
            </div>
            <div className="form-floating">
              <input
                type="text"
                pattern="[0-9]{10}"
                title="Please enter valid mobile."
                className="form-control input-box px-1"
                id="mobile"
                name="mobile"
                value={mobile}
                placeholder="name@example.com"
                onInvalid={(e) => {
                  return e.target.setCustomValidity(
                    "Please enter valid mobile number."
                  );
                }}
                onChange={(e) => {
                  setMobile(e.target.value);
                  return e.target.setCustomValidity("");
                }}
                required
              />
              <label className="ps-0" style={styles.resumeFormText}>
                Your Mobile Number
              </label>
            </div>
            <div className="form-floating">
              <input
                type="email"
                className="form-control input-box px-1"
                id="email"
                name="email"
                value={email}
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label className="ps-0" style={styles.resumeFormText}>
                Your e-mail address
              </label>
            </div>
            {/* <div className="textarea-container">
                <textarea
                  className="form-control label"
                  id="message"
                  placeholder="Type Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="4"
                  style={{ backgroundColor: "#eee" }}
                ></textarea>
              </div> */}
            {/* <Dropzone
                onDrop={(acceptedImage) => {
                  console.log("Accepted Files", acceptedImage);
                  // setCategoryImage(acceptedImage);
                }}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className="mt-4 d-flex justify-content-center align-items-center cursor-pointer"
                    style={styles.productImagesCard}
                    dark={true}
                  >
                    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                      <div
                        {...getRootProps()}
                        className="text-center h-100 w-100 d-flex justify-content-center align-items-center"
                      >
                        <input name="image_file" {...getInputProps()} />
                        {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <div>
                            <div style={{ fontSize: 14 }}>Add Image</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone> */}
            <div className="resume-upload-box mt-5 p-0 cursor-pointer" onClick={() => {
              // setUploaded(!uploaded);
            }}>
              {!uploaded && (
                // <Dropzone
                //   accept={{ 
                //     "application/octet-stream": [".pdf", ".doc", ".docx", ".odt"],}}
                //   noClick={false}
                //   onDrop={(acceptedFile) => {
                //     console.log("Accepted Files", acceptedFile);
                //     if (acceptedFile.length > 0) {
                //       setResume(acceptedFile);
                //       console.log(acceptedFile);
                //       setUploaded(true);
                //     } else {
                //       notify("Only pdf and word document formats are allowed", "warning");
                //     }
                //   }}
                // >
                //   {({ getRootProps, getInputProps, isDragActive }) => (
                //     <Row {...getRootProps()}>
                //       <Col className="d-flex justify-content-end my-2 col-5 px-0" sm={5}>
                //         <Image
                //           style={{ width: 100, objectFit:'contain' }}
                //           src="/assets/landing/upload_gif.gif"
                //         />
                //       </Col>
                //       <Col
                //         sm={7}
                //         className="col-7 d-flex justify-content-start align-items-center"
                //       >
                //         <div
                //           className="d-flex flex-column align-items-center"
                //           style={{ color: "#00A85A" }}
                //         >
                //           <div className="text-center" style={{ fontSize: 12 }}>
                //             Upload Your Resume Here
                //           </div>
                //           <div
                //             style={{ fontSize: 10, letterSpacing: 0.4 }}
                //             className="montserrat"
                //           >
                //             [ Word or PDF Only ]
                //           </div>
                //         </div>
                //       </Col>
                //     </Row>
                //   )}
                // </Dropzone>
                <Row onClick={() => openFilePicker()}>
                  <div class="dropZoneContainer">
                    <input ref={fileUploadRef} type="file" id="drop_zone" class="FileUpload" accept=".pdf,.doc,.docx,.odt" onChange={(e) => handleFileSelect(e)} />
                  </div>
                  <Col className="d-flex justify-content-end my-2 col-5 px-0" sm={5}>
                    <Image
                      style={{ width: 100, objectFit: 'contain' }}
                      src="/assets/landing/upload_gif.gif"
                    />
                  </Col>
                  <Col
                    sm={7}
                    className="col-7 d-flex justify-content-start align-items-center"
                  >
                    <div
                      className="d-flex flex-column align-items-center"
                      style={{ color: "#00A85A" }}
                    >
                      <div className="text-center" style={{ fontSize: 12 }}>
                        Upload Your Resume Here
                      </div>
                      <div
                        style={{ fontSize: 10, letterSpacing: 0.4 }}
                        className="montserrat"
                      >
                        [ Word or PDF Only ]
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {uploaded && <Row className="px-0">
                <Col className="d-flex justify-content-end my-2 col-4 px-0" sm={4}>
                  <Image
                    style={{ height: 100 }}
                    src="/assets/landing/resume-sample.svg"
                  />
                </Col>
                <Col
                  sm={6}
                  className="d-flex justify-content-start align-items-center col-6"
                >
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ color: "#00A85A" }}
                  >
                    <div style={{ fontSize: 12 }}>
                      {resume?.length > 0 ? resume[0].name : ""}
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-end col-2">
                  <Button variant="danger" onClick={() => {
                    setResume(null);
                    setUploaded(false);
                  }}>
                    <Image src="/assets/landing/remove-file.svg" />
                  </Button>
                </Col>
              </Row>}
            </div>
            <div className="text-center mt-4">
              <Button
                type={isSending ? "button" : "submit"}
                variant="primary"
                className="my-4 wrapped-button border-0 btn-main send-message-button"

              >
                {isSending ? (
                  <span>
                    <Spinner
                      className="me-2"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    SENDING ...
                  </span>
                ) : (
                  "SEND MESSAGE"
                )}
              </Button>
            </div>
          </Form>
        </Container>
      </Row>
      {/* </Breakpoint> */}
    </Container>
  );
};

const styles = {
  contactFormContainer: {
    fontFamily: "Montserrat Regular",
  },
  resumeFormText: {
    fontFamily: 16,
    color: "#9E9E9E",
  },
};

export default ResumeForm;
