import React from "react";
import { Card, Button, Image } from "react-bootstrap";

import "./ClientCard.scss";

const ClientCard = (props) => {
  const { item } = props;
  return (
    <Card className="clientCard m-1" style={{zIndex: 200}}>
      <Image className="clientCardImg" src={item.img} alt={item.alt} rounded />
    </Card>
  );
};

export default ClientCard;
