import React, { useContext, useState } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavBar from "components/NavBar";
import RecruiterCard from "components/RecruiterCard";
import "./HomeRecruiter.scss";
import { useTransition, useSpring, animated } from "react-spring";
import { RecruiterContext } from "navigation/HomeNavigation/HomeRecruiterNavigation/RecruiterContext";
import { useDispatch, useSelector } from "react-redux";
import { increment, decrement } from "actions";
import "components/RecruiterCard/RecruiterCard.scss";
import { useCurrentBreakpointName } from "react-socks";
import * as easings from "d3-ease";

const items = [
  {
    id: 1,
    img: "/assets/images/clients/jp_morgan.png",
    alt: "JP Morgan",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 2,
    img: "/assets/images/clients/credit_suisse.png",
    alt: "Credit suisse",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 3,
    img: "/assets/images/clients/northern_trust.png",
    alt: "Northern trust",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 4,
    img: "/assets/clients/deutsche_bank.png",
    alt: "Deutsche bank",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 5,
    img: "/assets/images/clients/hsbc.png",
    alt: "HSBC Bank",
    from: {
      opacity: 1,
      x: -100,
      y: 28,
      scale: 1.2,
      borderWidth: 2,
      borderColor: "#000",
    },
    from1: {
      opacity: 1,
      x: -730,
      y: 40,
      scale: 1.25,
      op: 0,
      backgroundColor: "#FFEACC",
    },
    enter: {
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      borderWidth: 0,
      borderColor: "#fff",
      backgroundColor: "#fff",
    },
    // enter: { opacity: 1, x: -100, y: 28, scale: 1.2, borderWidth: 2, borderColor: '#000' },
    leave: { opacity: 0 },
  },
  {
    id: 6,
    img: "/assets/images/clients/fis.png",
    alt: "FIS",
    from: {
      opacity: 1,
      x: -58,
      y: 28,
      scale: 1.2,
      borderWidth: 2,
      borderColor: "#000",
    },
    from1: {
      opacity: 1,
      x: -575,
      y: 40,
      scale: 1.25,
      op: 0,
      backgroundColor: "#FBEFD0",
    },
    enter: {
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      borderWidth: 0,
      borderColor: "#fff",
      backgroundColor: "#fff",
    },
    // enter: { opacity: 1, x: -58, y: 28, scale: 1.2, borderWidth: 2, borderColor: '#000' },
    leave: { opacity: 0 },
  },
  {
    id: 7,
    img: "/assets/clients/cognizant.png",
    alt: "Cognizant",
    from: {
      opacity: 1,
      x: -18,
      y: 28,
      scale: 1.2,
      borderWidth: 2,
      borderColor: "#000",
    },
    from1: {
      opacity: 1,
      x: -425,
      y: 40,
      scale: 1.25,
      op: 0,
      backgroundColor: "#DCEFDD",
    },
    enter: {
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      borderWidth: 0,
      borderColor: "#fff",
      backgroundColor: "#fff",
    },
    // enter: { opacity: 1, x: -18, y: 28, scale: 1.2, borderWidth: 2, borderColor: '#000' },
    leave: { opacity: 0 },
  },
  {
    id: 8,
    img: "/assets/images/clients/yash_technologies.png",
    alt: "Yash Tecnology",
    from: {
      opacity: 1,
      x: 23,
      y: 28,
      scale: 1.2,
      borderWidth: 2,
      borderColor: "#000",
    },
    from1: {
      opacity: 1,
      x: -275,
      y: 40,
      scale: 1.2,
      backgroundColor: "#E5DFEC",
      op: 0,
    },
    enter: {
      x: 0,
      y: 0,
      opacity: 1,
      scale: 1,
      borderWidth: 0,
      borderColor: "#fff",
      backgroundColor: "#fff",
    },
    // enter: { opacity: 1, x: 23, y: 28, scale: 1.2, borderWidth: 2, borderColor: '#000' },
    leave: { opacity: 0 },
  },
  {
    id: 9,
    img: "/assets/images/clients/sopra_steria.png",
    alt: "Sopra Steria",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 10,
    img: "/assets/images/clients/mandg.png",
    alt: "M&G Investment",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 11,
    img: "/assets/images/clients/fractal.png",
    alt: "Fractal",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 12,
    img: "/assets/images/clients/emc.png",
    alt: "EMC",
    from: { opacity: 0 },
    from1: {
      opacity: 1,
      x: -10,
      y: -75,
      scale: 1.25,
      backgroundColor: "#E1E7EA",
      op: 0,
    },
    enter: { x: 0, y: 0, opacity: 1, scale: 1, backgroundColor: "#fff" },
    leave: { opacity: 0 },
  },
  {
    id: 13,
    img: "/assets/images/clients/eclerx.png",
    alt: "eClerx",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 14,
    img: "/assets/images/clients/berkadia.png",
    alt: "Berkadia",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  {
    id: 15,
    img: "/assets/images/clients/something.png",
    alt: "more",
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
];

const Recruitment3 = (props) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.counters);

  let scrolled = false;

  const breakpoint = useCurrentBreakpointName();
  let wheelWidth = 0;
  let wheelBorderRadius = 0;
  let imageSrc = "";
  let alt = "";
  let wheelTop = 0;
  let wheelLeft = 0;
  let wheelLeftForRight = 0;
  let leftDiff = 0;

  switch (breakpoint) {
    case "xxlarge":
      wheelWidth = 990;
      wheelBorderRadius = 495;
      imageSrc = "/assets/images/home/dial-recruiter3-xxl.png";
      alt="Dial Extra Large"
      wheelTop = -450;
      wheelLeft = -825;
      leftDiff = -28;
      break;
    case "xlarge":
      wheelWidth = 792;
      wheelBorderRadius = 396;
      imageSrc = "/assets/images/home/dial-recruiter3.png";
      alt="Dial Large"
      wheelTop = -362;
      wheelLeft = -670;
      leftDiff = -22;
      break;
    default:
      wheelWidth = 1320;
      wheelBorderRadius = 660;
      imageSrc = "/assets/images/home/dial-mobile.png";
      alt="Dial Mobile"
      wheelTop = -597;
      wheelLeft = -1040;
      leftDiff = -40;
  }

  const [animatingLeft, setAnimatingLeft] = useState(true);
  const springProps1 = useSpring({
    from: {
      index1: `rotate(-18deg)`,
      index2: `rotate(-12deg)`,
      index3: `rotate(-6deg)`,
      index4: `rotate(-24deg)`,
      index5: `rotate(-6deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-120000deg)`,
      index2: `rotate(-12036deg)`,
      index3: `rotate(-1248deg)`,
      index4: `rotate(-42deg)`,
      index5: `rotate(-6deg)`,
    },
    config: { duration: 4000, easing: easings.easeCubicOut },
    onRest: () => {
      scrolled = true;
      return setAnimatingLeft(false);
    },
    delay: 1000,
  });

  const [deg, setDeg] = useState(0);

  const springProps1Slow = useSpring({
    loop: true,
    from: {
      index1: `rotate(0deg)`,
      index2: `rotate(-36deg)`,
      index3: `rotate(-48deg)`,
      index4: `rotate(-42deg)`,
      index5: `rotate(-6deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-840deg)`,
      index2: `rotate(-120deg)`,
      index3: `rotate(-60deg)`,
      index4: `rotate(-48deg)`,
      index5: `rotate(-6deg)`,
    },
    config: { duration: 720000 },
    delay: 8000,
    onChange: () => {
      scrolled = true;
    }
  });

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        setDisable(true);
        dispatch(decrement());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (scrolled) {
        setDisable(true);
        dispatch(increment());
      }
    }
  };

  const transitions = useTransition(items, {
    key: (item) => item.key,
    from: (item) => {
      if (animatingLeft) {
        return count.recruiterPrevState > count.recruiterCurrentState
          ? item.from1
          : item.from;
      }
    },
    enter: (item) => item.enter,
    leave: (item) => item.leave,
    onRest: () => {
      scrolled = true;
    },
  });

  const [disable, setDisable] = useState(false);
  const arr = [5, 6, 7, 8];

  return (
    <Container
      className="p-0 pt-5 d-flex flex-column position-absolute h-100"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container className="px-5" fluid>
        <Row>
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="recruiter-main-sidetext text-white">
              Our clients continue to show trust <br /> and faith in us. We have
              partnered
              <br /> with our clients to help them
              <br />{" "}
              <span className="overflow-hidden">
                choose over{" "}
                <div
                  className="justify-content-center bouncing-letter-recruiter3 position-relative overflow-hidden"
                  style={{ display: "inline-block" }}
                >
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index1
                        : springProps1Slow.index1,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index2
                        : springProps1Slow.index2,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index3
                        : springProps1Slow.index3,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff * 2,
                    }}
                  >
                    <Image src={imageSrc} alt={alt} className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: animatingLeft
                        ? springProps1.index4
                        : springProps1Slow.index4,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff * 3,
                    }}
                  >
                    <Image src={imageSrc} alt="Dial Mobile" className="position-relative" />
                  </animated.div>
                  <animated.div
                    className="p-0 position-absolute"
                    style={{
                      transform: springProps1.index5,
                      borderRadius: wheelBorderRadius,
                      width: wheelWidth,
                      height: wheelWidth,
                      top: wheelTop,
                      left: wheelLeft + leftDiff * 4,
                    }}
                  >
                    <Image src={imageSrc} alt="Dial Mobile" className="position-relative" />
                  </animated.div>
                </div>
              </span>
              <span>candidates</span>
              <br /> till date
            </div>
          </Col>
          <Col
            lg={6}
            className="text-center my-5 py-5 px-2 d-flex justify-content-end"
          >
            <Row>
              <Col className="px-5" lg={{ span: 9, offset: 2 }}>
                <Row>
                  {/* {items.map((item, index) => {
                    return <RecruiterCard key={item.id} item={item} />;
                  })} */}
                  {transitions((props, item) => {
                    if (item) {
                      return (
                        <Col lg={item.id == 15 ? 6 : 3} key={item.id}>
                          {disable ? (
                            <Card
                              as={animated.div}
                              className={`d-flex justify-content-center align-items-center my-3 ${
                                item.id == 15
                                  ? "recruiterCardClientLast"
                                  : "recruiterCardClient"
                              }`}
                              style={{
                                ...props,
                                opacity: arr.includes(item.id) ? 0 : 1,
                              }}
                            >
                              {item.id == 15 && (
                                <p className="and_more_text">And More...</p>
                              )}
                              {item.id != 15 && (
                                <Image
                                  className="recruiterCardClientImg"
                                  src={item.img}
                                  alt={item.alt}
                                  rounded
                                />
                              )}
                            </Card>
                          ) : (
                            <Card
                              as={animated.div}
                              className={`d-flex justify-content-center align-items-center my-3 ${
                                item.id == 15
                                  ? "recruiterCardClientLast"
                                  : "recruiterCardClient"
                              }`}
                              style={props}
                            >
                              {item.id == 15 && (
                                <p className="and_more_text">And More...</p>
                              )}
                              {item.id != 15 && (
                                <Image
                                  className="recruiterCardClientImg"
                                  src={item.img}
                                  alt={item.alt}
                                  rounded
                                />
                              )}
                            </Card>
                          )}
                        </Col>
                      );
                    } else {
                      return <div />;
                    }
                  })}
                </Row>
              </Col>
              <Col lg={3} className="d-flex flex-column justify-content-end">
                <div
                  className="d-flex flex-column position-fixed"
                  style={{ right: 50, bottom: 50 }}
                >
                  <Image
                    className="arrow my-2"
                    src="/assets/svgs/get-in-touch/arrow_up.svg"
                    alt="Arrow up"
                    onClick={() => {
                      setDisable(true);
                      dispatch(decrement());
                    }}
                  />
                  <Image
                    className="arrow"
                    src="/assets/svgs/get-in-touch/arrow_down.svg"
                    alt="Arrow Down"
                    onClick={() => {
                      setDisable(true);
                      dispatch(increment());
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    backgroundColor: "#00A85A",
    overflow: 'hidden'
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Recruitment3;
