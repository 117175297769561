import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CeoCorner from "screens/CeoCorner";

const CeoCornerNavigation = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="">
          <CeoCorner />
        </Route>
      </Switch>
    </Router>
  );
};

export default CeoCornerNavigation;
