import React, { useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { useCurrentBreakpointName } from "react-socks";
import { increment1, decrement1 } from "actions";
import { Link } from "react-router-dom";
import NavBar from "components/NavBar";

import "./HomeCandidate.scss";

const items = [
  {
    id: 1,
    content:
      "Thanks for your support. The entire process with Credence HR Services was so impressive, in future if I have any references, I would surely recommend Credence HR Services. I would Rate 9.5 out of 10. I am so thankful to the entire team of Credence HR Services.",
    author: "Rajesh M",
  },
  {
    id: 2,
    content:
      "Just want to take a moment to thankyou for providing the support from explaining the job opportunity, coordinating interviews and seamless onboardingthrough Credence HR Services. Thank you for always being proactive in explaining all the process.Kudos to you and your team!!",
    author: "Priya Srivastava",
  },
];

const Candidate2 = () => {
  const dispatch = useDispatch();

  const [text, setText] = useState(true);

  const count = useSelector((state) => state.counters);

  let scrolled = false;

  const textTransition = useTransition(text, {
    from:
      count.candidatePrevState > count.candidateCurrentState
        ? { y: 0, opacity: 0 }
        : { y: 200, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 200, opacity: 0 },
    config: { duration: 1000, tenstion: 200 },
    onRest: () => {
      scrolled = true;
    }
  });

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        setText(false)
        dispatch(decrement1());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (scrolled) {
        dispatch(increment1());
      }
    }
  };

  return (
    <Container
      className="p-0 d-flex flex-column overflow-hidden"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container className="h-100" fluid>
        <Row className="h-100">
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-center"
          >
            {textTransition((props, item) =>
              item ? (
                <animated.div style={props}>
                  <div className="recruiter-main-sidetext">
                    To help you find the optimal job,<br /> we have partnered with Hyrebook
                  </div>
                  <Button
                    type="link"
                    href="https://www.hyrebook.com"
                    target="_blank"
                    className="my-5 btn-main"
                  >
                    <div>GO TO HYREBOOK</div>
                  </Button>
                </animated.div>
              ) : (
                ""
              )
            )}
          </Col>
          <Col
            lg={6}
            className="d-flex flex-column background-candidate1 justify-content-center"
            style={{ height: '100vh' }}
          >
            <div className="d-flex justify-content-end">
              <Image
                className="hyrebook_text"
                src="/assets/svgs/candidate/candidate1/hyrebook_text.svg"
                alt="hyrebook-text"
              />
            </div>
            <div className="d-flex justify-content-center py-3">
              <Image
                className="candidate_gif"
                src="/assets/svgs/candidate/candidate1/candidate1.gif"
                alt="candidate"
              />
            </div>
            {textTransition((props, item) =>
              item ? (
                <animated.div
                  className="d-flex justify-content-center"
                  style={props}
                >
                  <Image
                    className="hyrebook_text1"
                    src="/assets/svgs/candidate/candidate1/hyrebook_text1.png"
                    alt="hyrebook-text"
                  />
                </animated.div>
              ) : (
                <div />
              )
            )}
            <div
              className="d-flex flex-column position-fixed"
              style={{ right: 50, bottom: 50 }}
            >
              <Image
                className="arrow my-2"
                src="/assets/svgs/candidate/arrow_up.svg"
                alt="Arrow up"
                onClick={() => {
                  setText(false);
                  dispatch(decrement1());
                }}
              />
              <Image
                className="arrow"
                src="/assets/svgs/candidate/arrow_down.svg"
                alt="Arrow Down"
                onClick={() => {
                  dispatch(increment1());
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    overflow: 'hidden'
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Candidate2;
