const initialState = {
  recruiterCurrentState: 1,
  recruiterPrevState: 1,
  candidateCurrentState: 1,
  candidatePrevState: 1,
  currentMode: "recruiter"
};

const reducer = (state = initialState, action) => {
  console.log(action);
  console.log(action.data);
  switch (action.type) {
    case "RESETALLPAGES":
      console.log(state);
      console.log("RESETTING")
      state.recruiterCurrentState = action.data.currentPage;
      state.recruiterPrevState = action.data.goToPage;
      state.candidateCurrentState = action.data.currentPage;
      state.candidatePrevState = action.data.goToPage;
      state.currentMode = initialState.currentMode;
      console.log(state.recruiterCurrentState)
      return state;
    case "INCREMENT":
      console.log(state);
      state.recruiterPrevState = state.recruiterCurrentState;
      state.recruiterCurrentState = state.recruiterCurrentState + 1;
      return state;
    case "DECREMENT":
      state.recruiterPrevState = state.recruiterCurrentState;
      state.recruiterCurrentState = state.recruiterCurrentState - 1;
      return state;
    case "RESET":
      return 0;
    case "SWITCHTOPAGE":
      state.recruiterPrevState = action.data.currentPage;
      state.recruiterCurrentState = action.data.goToPage;
      return state;
    case "INCREMENT1":
      state.candidatePrevState = state.candidateCurrentState;
      state.candidateCurrentState = state.candidateCurrentState + 1;
      return state;
    case "DECREMENT1":
      state.candidatePrevState = state.candidateCurrentState;
      state.candidateCurrentState = state.candidateCurrentState - 1;
      return state;
    case "SWITCHTOPAGE1":
      state.candidatePrevState = action.data.currentPage;
      state.candidateCurrentState = action.data.goToPage;
      return state;
    case "SWITCHMODE":
      console.log(state.currentMode)
      state.currentMode = action.data.mode;
      console.log(state.currentMode)
      return state;
    default:
      return state;
  }
};
export default reducer;
