import React, { useState } from "react";
import { Container, Row, Col, Image, Carousel, Button } from "react-bootstrap";
import { Breakpoint, useCurrentBreakpointName } from "react-socks";
import { useDispatch, useSelector } from "react-redux";
import {
  useTransition,
  animated,
  useSpring,
  useTrail,
  config,
} from "react-spring";

import * as easings from "d3-ease";

import { increment1, decrement1 } from "actions";
import { Link } from "react-router-dom";
import NavBar from "components/NavBar";

import "./HomeCandidate.scss";

const items = [
  {
    id: 1,
    content:
      "Thanks for your support. The entire process with Credence HR Services was so impressive, in future if I have any references, I would surely recommend Credence HR Services. I would Rate 9.5 out of 10. I am so thankful to the entire team of Credence HR Services.",
    author: "Rajesh M",
  },
  {
    id: 2,
    content:
      "Just want to take a moment to thankyou for providing the support from explaining the job opportunity, coordinating interviews and seamless onboardingthrough Credence HR Services. Thank you for always being proactive in explaining all the process.Kudos to you and your team!!",
    author: "Priya Srivastava",
  },
];

const Candidate5 = () => {
  const breakpoint = useCurrentBreakpointName();

  const count = useSelector((state) => state.counters);
  const dispatch = useDispatch();

  const [animating, setAnimating] = useState(true);

  let wheelWidth = 0;
  let wheelBorderRadius = 0;
  let imageSrc = "";
  let alt = "";
  let wheelTop = 0;
  let wheelLeft = 0;
  let wheelLeftForRight = 0;
  let leftDiff = 0;

  switch (breakpoint) {
    case "xxlarge":
      wheelWidth = 2200;
      wheelBorderRadius = 1100;
      imageSrc = "/assets/images/home/dial.png";
      alt = "Dial"
      wheelTop = -1000;
      wheelLeft = -1792;
      leftDiff = -65;
      break;
    case "xlarge":
      wheelWidth = 2200;
      wheelBorderRadius = 1100;
      imageSrc = "/assets/images/home/dial.png";
      alt = "Dial"
      wheelTop = -1000;
      wheelLeft = -1820;
      wheelLeftForRight = -800;
      leftDiff = -65;
      break;
    default:
      wheelWidth = 1320;
      wheelBorderRadius = 660;
      imageSrc = "/assets/images/home/dial-mobile.png";
      alt = "Dial Mobile"
      wheelTop = -597;
      wheelLeft = -1040;
      leftDiff = -40;
  }

  let scrolled = false;

  const springProps1 = useSpring({
    from: {
      index1: `rotate(0deg)`,
      index2: `rotate(0deg)`,
      index3: `rotate(-6deg)`,
      opacity: 0,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-1080deg)`,
      index2: `rotate(-108deg)`,
      index3: `rotate(-18deg)`,
      opacity: 1,
    },
    config: { duration: 4000, easing: easings.easeCubicOut },
    delay: 1000,
    onChange: () => {
      scrolled = true;
    },
    onRest: () => {
      setAnimating(false);
    },
  });

  const springProps1Slow = useSpring({
    loop: true,
    from: {
      index1: `rotate(0deg)`,
      index2: `rotate(-108deg)`,
      index3: `rotate(-18deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-120deg)`,
      index2: `rotate(-120deg)`,
      index3: `rotate(-24deg)`,
    },
    config: { duration: 180000 },
    delay: 5000,
    onChange: () => {
      scrolled = true;
    }
  });

  const [sectionToggle, setSectionToggle] = useState(true);
  console.log(count);

  const sectionTransition = useTransition(sectionToggle, {
    from:
      count.cadidatePrevState > count.candidateCurrentState
        ? { y: 0, opacity: 1 }
        : { y: 200, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 200, opacity: 0 },
    config: { duration: 1000 },
  });

  const [text, setText] = useState(true);

  //   const textTransition = useTransition(text, {
  //     from:
  //       count.cadidatePrevState > count.candidateCurrentState
  //         ? { y: 0, opacity: 1 }
  //         : { y: 200, opacity: 0 },
  //     enter: { y: 0, opacity: 1 },
  //     leave: { y: 200, opacity: 0 },
  //     config: { duration: 1000 }
  //   });

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        setSectionToggle(false);
        setText(false);
        dispatch(decrement1());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (scrolled) {
        dispatch(increment1());
      }
    }
  };

  return (
    <Container
      className="p-0 pt-5 d-flex flex-column"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container
        className="h-100 d-flex flex-column justify-content-center align-items-center"
        fluid
      >
        {sectionTransition((props, item) => {
          if (item) {
            return (
              <animated.div style={props}>
                <Row>
                  {text && (
                    <Col
                      as={animated.div}
                      lg={12}
                      className="recruiter-main-sidetext mt-5"
                      style={{ opacity: springProps1.opacity }}
                    >
                      Open opportunities for you
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col className="my-5" lg={12}>
                    <div className="home-stats mt-4">
                      <div className="d-flex justify-content-center mb-1 bouncing-letter position-relative overflow-hidden">
                        <animated.div
                          className="p-0 position-absolute"
                          style={{
                            transform: animating
                              ? springProps1.index1
                              : springProps1Slow.index1,
                            borderRadius: wheelBorderRadius,
                            width: wheelWidth,
                            height: wheelWidth,
                            top: wheelTop,
                            left: wheelLeft,
                          }}
                        >
                          <Image src={imageSrc} alt={alt} className="position-relative" />
                        </animated.div>
                        <animated.div
                          className="p-0 position-absolute"
                          style={{
                            transform: animating
                              ? springProps1.index2
                              : springProps1Slow.index2,
                            borderRadius: wheelBorderRadius,
                            width: wheelWidth,
                            height: wheelWidth,
                            top: wheelTop,
                            left: wheelLeft + leftDiff,
                          }}
                        >
                          <Image src={imageSrc} alt={alt} className="position-relative" />
                        </animated.div>
                        <animated.div
                          className="p-0 position-absolute"
                          style={{
                            transform: animating
                              ? springProps1.index3
                              : springProps1Slow.index3,
                            borderRadius: wheelBorderRadius,
                            width: wheelWidth,
                            height: wheelWidth,
                            top: wheelTop,
                            left: wheelLeft + leftDiff * 2,
                          }}
                        >
                          <Image src={imageSrc} alt={alt} className="position-relative" />
                        </animated.div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="text-center mb-4">
                  <div>
                    <Button
                      type="link"
                      href="/get-in-touch"
                      target="_blank"
                      className="btn-main"
                    >
                      <div>GET IN TOUCH</div>{" "}
                    </Button>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-center align-items-center">
                    <Image
                      className="candidate4-hyrebook-image"
                      src="/assets/svgs/candidate/candidate1/hyrebook_text1.png"
                      alt="hyrebook-text"
                      style={{ maxWidth: '100%' }}
                    />
                  </div>
                </Row>
              </animated.div>
            );
          } else {
            return <div />;
          }
        })}

        <Row className="text-center">
          <div>
            <div
              className="d-flex flex-column position-fixed"
              style={{ right: 50, bottom: 50 }}
            >
              <Image
                className="arrow my-2"
                src="/assets/svgs/candidate/arrow_up.svg"
                alt="Arrow up"
                onClick={() => {
                  setSectionToggle(false);
                  setText(false);
                  setTimeout(function () {
                    dispatch(decrement1());
                  }, 1000);
                }}
              />
              <Image
                className="arrow"
                src="/assets/svgs/candidate/arrow_down.svg"
                alt="Arrow Down"
                onClick={() => {
                  dispatch(increment1());
                }}
              />
            </div>
          </div>
        </Row>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Candidate5;
