import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Breakpoint } from "react-socks";
import NavBar from "components/NavBar";
import "./CeoCorner.scss";
import Footer from "components/Footer";
import ContactForm from "components/ContactForm";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const CeoCorner = () => {

  const location = useLocation();
  return (
    <>
      {/* <Helmet>
        <title>{`Reema Maheshwari |CEO| Credence HR Services|Job Consultancy`}</title>
        <meta name="description" content='Reema built one of the best HR services consultancy and recruitment consultants that offers PAN India jobs and open roles in Banking and in IT.' />
        <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
      </Helmet> */}
      <Container className="p-0 pt-5" fluid style={styles.parentContainer}>
        {/* <NavBar /> */}
        <Container className="px-lg-5 d-flex flex-column" fluid>
          <Row>
            <Col
              lg={6}
              className="d-flex justify-content-center align-items-center flex-column order-2 order-lg-1"
            >
              <Row>
                <div
                  className="col-auto text-right mx-0 px-0 ceo-quotation-mark d-none d-lg-flex"
                >
                  “
                </div>
                <div
                  className="col flex-grow-1 my-4 py-1 ceo-quote px-4"
                >
                  <div>
                    Credence HR Services is shaping the future of work. We are not just a recruitment firm. We are a catalyst for change, a bridge between talent and opportunity.

                    Our mission is to empower individuals to reach their full potential and to help organizations build their dream teams. We combine cutting-edge technology with a deep understanding of our client's needs and the talent marketplace.

                    But our success is not just about the technology or the data we have at our fingertips. It's about our people, the heart and soul of our organization. Our team comprises of experts in their fields, individuals dedicated to making a difference and delivering exceptional results.

                    We are not just filling job openings. We are changing lives. That is something to be proud of. In an ever-evolving world, we continue to adapt and evolve to stay ahead of the curve and to lead the way.
                    We will make a lasting impact on the world of work and create a brighter future for all.
                    <span className="d-inline-flex align-items-start">
                      <span>&nbsp;</span>
                      <span
                        className="ms-2 ceo-quotation-mark d-none d-lg-flex"
                        style={{ lineHeight: 0.9 }}
                      >
                        ”
                      </span>
                    </span>
                  </div>
                  <div
                    className="text-left mt-5 ceo-mark"
                  >
                    Reema Maheshwari
                    <br /> CEO | Credence HR Services
                  </div>
                </div>
              </Row>
            </Col>
            <Col
              lg={6}
              className="text-center px-2 d-flex justify-content-center align-items-center order-1 order-lg-2"
            >
              <Image src="/assets/svgs/ceo-corner/ceo-2.png" className="ceo-profile d-none d-lg-flex" alt="ceo" />
              <Image src="/assets/svgs/ceo-corner/ceo-mobile.png" className="ceo-profile d-lg-none" alt="ceo" />
            </Col>
          </Row>
          <Breakpoint medium down>
            <ContactForm page="CEO Corner" />
            <Footer />
          </Breakpoint>
        </Container>
      </Container>
      <Footer />
    </>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default CeoCorner;
