import React, { useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  Button,
  Image,
  NavDropdown,
} from "react-bootstrap";
import "./NavBar.scss";
import { useDispatch, useSelector } from "react-redux";
import { switchMode, switchToPage, switchToPage1 } from "actions";
import { Redirect, useHistory, Link, useLocation } from "react-router-dom";
import customHistory from "customHistory";
import { Breakpoint } from "react-socks";
import { useTransition, animated } from "react-spring";

const NavBar = (props) => {
  const { current_page } = props;
  const dispatch = useDispatch();
  const count = useSelector((state) => state.counters);
  const location = useLocation();
  console.log(location);

  const [open, setOpen] = useState(false);

  // let current = location.pathname.replace(/^\/|\/$/g, "");
  // console.log(current);
  let have_menu = false;
  let landing = false;

  const params = location.pathname.split("/").filter((item) => item != "");
  const current = params.length > 0 ? params[0] : "";
  const param2 = params.length > 1 ? params[1] : "";
  const param3 = params.length > 1 ? params[2] : "";

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  switch (current) {
    case "home":
      have_menu = true;
      break;
    case "about-us":
      have_menu = true;
      break;
    case "ceo-corner":
      have_menu = true;
      break;
    case "blogs":
      have_menu = true;
      break;
    case "page-not-found":
      have_menu = true;
      break;
    case "landing":
      // landing = true;
      have_menu = true;
      break;
    default:
      have_menu = false;
  }

  const transition = useTransition(open, {
    from: { y: "-100%" },
    enter: {
      y: "0%",
    },
    leave: {
      y: "-100%",
    },
    config: { duration: 300 },
  });

  if (landing) {
    return (
      <Navbar variant="white" style={{ zIndex: 1000 }} fixed="top">
        <Container fluid>
          <Navbar.Brand
            // as={Link}
            className="ps-md-5 ps-2 nav-brand"
            //  to="/"
            onClick={() => {
              handleNavigationClick("/");
            }}
          >
            <Image
              src="/assets/landing/credence_logo.png"
              alt="credence hr service logo"
              style={{ width: 150 }}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }

  return (
    <Navbar
      className="main-nav"
      bg="white"
      variant="white"
      style={{ zIndex: 1000 }}
      fixed="top"
    >
      <Container fluid>
        <Navbar.Brand
          // as={Link}
          className="ps-md-5 ps-2 nav-brand"
          //  to="/"
          onClick={() => {
            handleNavigationClick("/");
          }}
        >
          <Image
            src="/assets/landing/credence_logo.svg"
            alt="credence hr service logo"
            style={{ width: 150 }}
          />
        </Navbar.Brand>
        {!have_menu && <Nav className="main-nav-menu"></Nav>}
        {have_menu && (
          <Nav className="ms-auto me-5 main-nav-menu d-none d-lg-flex">
            <Nav.Link
              // as={Link}
              className="main-nav-menu-link mx-2 d-flex flex-column justify-content-center align-items-center"
              // to="/home"
              href="/home"
              onClick={() => {
                if (count.currentMode === "recruiter") {
                  dispatch(switchToPage(1, 1));
                } else {
                  dispatch(switchToPage1(1, 1));
                }
              }}
            >
              <div>HOME</div>
              {current === "home" && (
                <div className="bg-primary border-underline" />
              )}
            </Nav.Link>
            <NavDropdown
              title="COMPANY"
              id="basic-nav-dropdown"
              className="nav-dropdowns main-nav-menu-link mx-2 d-flex flex-column justify-content-center align-items-center"
            >
              <NavDropdown.Item href="/about-us/">ABOUT US</NavDropdown.Item>
              <NavDropdown.Item href="/ceo-corner/">
                CEO CORNER
              </NavDropdown.Item>
              <NavDropdown.Item href="/landing/recruitment-specialist">
                CAREER
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              // as={Link}
              className="main-nav-menu-link mx-2 d-flex flex-column justify-content-center align-items-center"
              href="/blogs"
            // to="/blogs"
            >
              <div>BLOGS</div>
              {current === "blogs" && (
                <div className="bg-primary border-underline" />
              )}
            </Nav.Link>
            {count.currentMode === "recruiter" && (
              <Nav.Link
                // as={Link}
                className="main-nav-menu-link mx-2 d-flex flex-column justify-content-center align-items-center"
                // to="/home"
                href="/home"
                onClick={() => {
                  dispatch(switchMode("candidate"));
                  dispatch(switchToPage1(1, 1));
                }}
              >
                <div>SWITCH TO CANDIDATE</div>
              </Nav.Link>
            )}
            {count.currentMode === "candidate" && (
              <Nav.Link
                // as={Link}
                className="main-nav-menu-link mx-2 d-flex flex-column justify-content-center align-items-center"
                // to="/home"
                href="/home"
                onClick={() => {
                  dispatch(switchMode("recruiter"));
                  dispatch(switchToPage(1, 1));
                }}
              >
                <div>SWITCH TO RECRUITER</div>
              </Nav.Link>
            )}
            <Button
              // as={Link}
              // to="/home"
              className="btn-main ms-3 my-1"
              onClick={() => {
                count.currentMode == "candidate"
                  ? dispatch(switchToPage1(1, 6))
                  : dispatch(switchToPage(1, 6));
                handleNavigationClick("/home");
              }}
            >
              GET IN TOUCH
            </Button>
          </Nav>
        )}
        {have_menu && (
          <Breakpoint medium down>
            {transition(
              (props, item) =>
                item && (
                  <Container
                    as={animated.div}
                    className="position-fixed w-100"
                    fluid
                    style={{
                      width: "100%",
                      height: "100vh",
                      y: props.y,
                      top: 74,
                      left: 0,
                      backgroundColor: "#F5FFFA",
                      zIndex: 999,
                    }}
                  >
                    <Nav className="mx-auto main-nav-menu mt-1 d-flex align-items-start justify-content-start flex-column d-lg-none">
                      <Nav.Link
                        // as={Link}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        //to="/home"
                        href="/home"
                        style={
                          current === "home"
                            ? {
                              borderLeft: "14px solid rgba(0, 168, 90, 1)",
                              fontWeight: "bold",
                            }
                            : { borderLeft: "14px solid rgba(0, 168, 90, 0)" }
                        }
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <div>HOME</div>
                        {current === "home" && (
                          <div className="bg-primary border-underline" />
                        )}
                      </Nav.Link>
                      <Nav.Link
                        // as={Link}
                        href="/ceo-corner"
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        style={
                          current === "about-us"
                            ? {
                              borderLeft: "14px solid rgba(0, 168, 90, 1)",
                              fontWeight: "bold",
                            }
                            : { borderLeft: "14px solid rgba(0, 168, 90, 0)" }
                        }
                        // onClick={() => {
                        //   customHistory.push("/about-us");
                        // }}
                        // to="/about-us"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <div>ABOUT US</div>
                        {current == "about-us" && (
                          <div className="bg-primary border-underline" />
                        )}
                      </Nav.Link>
                      <Nav.Link
                        // as={Link}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        style={
                          current === "ceo-corner"
                            ? { borderLeft: "14px solid rgba(0, 168, 90, 1)" }
                            : { borderLeft: "14px solid rgba(0, 168, 90, 0)" }
                        }
                        href="/ceo-corner"
                        // to="/ceo-corner"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <div>CEO CORNER</div>
                        {current == "ceo-corner" && (
                          <div className="bg-primary border-underline" />
                        )}
                      </Nav.Link>
                      <Nav.Link
                        // as={Link}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        style={
                          current.includes("landing")
                            ? { borderLeft: "14px solid rgba(0, 168, 90, 1)" }
                            : { borderLeft: "14px solid rgba(0, 168, 90, 0)" }
                        }
                        // href="/ceo-corner"
                        // to="/landing/recruitment-specialist"
                        href="/landing/recruitment-specialist"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <div>CAREER</div>
                        {current.includes("landing") && (
                          <div className="bg-primary border-underline" />
                        )}
                      </Nav.Link>
                      <Nav.Link
                        // as={Link}
                        className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                        style={
                          current === "blogs"
                            ? { borderLeft: "14px solid rgba(0, 168, 90, 1)" }
                            : { borderLeft: "14px solid rgba(0, 168, 90, 0)" }
                        }
                        // href="/ceo-corner"
                        // to="/blogs"
                        href="/blogs"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <div>BLOGS</div>
                        {current === "blogs" && (
                          <div className="bg-primary border-underline" />
                        )}
                      </Nav.Link>
                      {count.currentMode == "recruiter" && (
                        <Nav.Link
                          // as={Link}
                          className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                          // to="/home"
                          href="/home"
                          style={{
                            borderLeft: "14px solid rgba(0, 168, 90, 0)",
                          }}
                          onClick={() => {
                            dispatch(switchMode("candidate"));
                            setOpen(false);
                          }}
                        >
                          <div>SWITCH TO CANDIDATE</div>
                        </Nav.Link>
                      )}
                      {count.currentMode === "candidate" && (
                        <Nav.Link
                          // as={Link}
                          className="main-nav-menu-link m-2 d-flex flex-column justify-content-center align-items-center"
                          // to="/home"
                          href="/home"
                          style={{
                            borderLeft: "14px solid rgba(0, 168, 90, 0)",
                          }}
                          onClick={() => {
                            dispatch(switchMode("recruiter"));
                            setOpen(false);
                          }}
                        >
                          <div>SWITCH TO RECRUITER</div>
                        </Nav.Link>
                      )}
                      <Nav.Link
                        // as={Link}
                        className="m-2 text-white"
                        // to="/home"
                        href="/home"
                        style={{ borderLeft: "14px solid rgba(0, 168, 90, 0)" }}
                        onClick={() => {
                          count.currentMode === "candidate"
                            ? dispatch(switchToPage1(1, 6))
                            : dispatch(switchToPage(1, 6));
                          setOpen(false);
                        }}
                      >
                        <Button className="btn-main">GET IN TOUCH</Button>
                      </Nav.Link>
                    </Nav>
                  </Container>
                )
            )}
            {open && (
              <Image
                src="/assets/images/home/closicon.svg"
                style={{ width: 30, height: 30 }}
                onClick={() => {
                  setOpen(!open);
                }}
              />
            )}
            {!open && (
              <Image
                src="/assets/images/home/coolicon.svg"
                alt="coolicon"
                style={{ width: 35, height: 40 }}
                onClick={() => {
                  setOpen(!open);
                }}
              />
            )}
          </Breakpoint>
        )}
      </Container>
    </Navbar>
  );
};

export default NavBar;
