import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import "./ContactForm.scss";

import Amplify, { API } from "aws-amplify";
import { Breakpoint } from "react-socks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import axios from "axios";

const ContactForm = (props) => {
  // Amplify.configure({
  //   API: {
  //     endpoints: [
  //       {
  //         name: "credenceContactUs",
  //         endpoint: "https://dswrvax32k.execute-api.ap-southeast-1.amazonaws.com/dev",
  //         custom_header: async () => {
  //           return { Authorization: 'token' }
  //           // Alternatively, with Cognito User Pools use this:
  //           // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
  //           // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
  //         }
  //       }
  //     ]
  //   }
  // });

  const { pageType, is_light, page } = props;
  const is_dark = (pageType === "aboutUs");

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [save_in_spreadsheet, setSaveInSpreadsheet] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const location = useLocation();


  const sendEmail = async (e) => {
    setIsSending(true);
    e.preventDefault();


    const apiName = "contactus";
    const path = "/contactus";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        // OPTIONAL
        name: name,
        mobile: mobile,
        email: email,
        page: page,
        company_name: company_name,
        message: message,
        save_in_spreadsheet: true,
      },
    };

    // API.post(apiName, path, myInit)
    //   .then((response) => {
    //     if (response.data.success) {
    //       console.log(response);
    //       setIsSending(false);
    //       notify("Thank you for submitting response. We'll contact you soon! ");
    //       setName("");
    //       setMobile("");
    //       setEmail("");
    //       setCompanyName("");
    //       setMessage("");
    //     }
    //   })
    //   .catch((error) => {
    //     setIsSending(false);
    //     console.log(error);
    //   });
    try {
      const response = await axios.post("contactus/", {
        name: name,
        mobile: mobile,
        email: email,
        page: page,
        company_name: company_name,
        message: message,
        save_in_spreadsheet: true
      });
      if (response.status == 200) {
        console.log(response);
        setIsSending(false);
        notify("Thank you for submitting response. We'll contact you soon! ");
        setName("");
        setMobile("");
        setEmail("");
        setCompanyName("");
        setMessage("");
      }
    }
    catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const notify = (msg) => toast.success(msg);

  return (
    <Row>
      <Breakpoint style={pageType === 'aboutUs' ? styles.contactFormContainerLu : {}} large up>
        <Row>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
          <Col
            lg={6}
            className={`${pageType === "aboutUs" ? "text-secondary-aboutus" : "text-white"
              } d-flex justify-content-center py-5`}
          >
            <div>
              <div>
                {location.pathname === '/contact-us' ? (<h1 className="title">Get in touch</h1>) : (<h4 className="title">Get in touch</h4>)}

              </div>
              <div className="my-5">
                <div className="py-1 subtitle d-flex align-items-center">
                  <Image
                    className="mx-3 contact-icons"
                    src={`/assets/svgs/get-in-touch/location_icon${is_dark ? "_dark" : ""
                      }.svg`}
                    alt='location icon'
                  />
                  AWFIS, GK Mall, 3rd Floor, Pimple Saudagar, Pune-411027
                </div>
                <div className="py-1 mt-5 subtitle d-flex align-items-center cursor-pointer">
                  <a className={`text-decoration-none ${pageType == "aboutUs" ? "text-secondary-aboutus" : "text-white"}`} href="mailto:credence@credencehrservices.com" target="_blank">
                    <Image
                      className="mx-3 contact-icons"
                      src={`/assets/svgs/get-in-touch/email_icon${is_dark ? "_dark" : ""
                        }.svg`}
                      alt='email icon'
                    />
                    credence@credencehrservices.com
                  </a>
                </div>
                <div className="mt-5 ms-2 py-2">
                  <a className="mx-2" target="_blank" href="https://www.linkedin.com/company/credence-hr-services/">
                    <Image
                      className="social-icons"
                      src={`/assets/svgs/get-in-touch/linkedin_icon${is_dark ? "_dark" : ""
                        }.svg`}
                      alt="linkedin icon"
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                  <a className="mx-2" target="_blank" href="https://www.facebook.com/Credencehrservices">
                    <Image
                      className="social-icons"
                      alt="facebook icon"
                      src={`/assets/svgs/get-in-touch/facebook_icon${is_dark ? "_dark" : ""
                        }.svg`}
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                  <a className="mx-2" target="_blank" href="https://www.instagram.com/credencehr/">
                    <Image
                      className="social-icons"
                      alt="instagram icon"
                      src={`/assets/svgs/get-in-touch/instagram_icon${is_dark ? "_dark" : ""
                        }.svg`}
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            className={`mt-5 px-3 py-3 pt-4 ${is_dark ? "contactform_background" : ""
              }`}
          // style={{ backgroundColor: is_light ? "" : "#06575b" }}
          >
            <Row>
              <Col lg={10}>
                <Form
                  className={`bg-white px-5 py-4 formbox`}
                  onSubmit={sendEmail}
                >
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control input-box px-1"
                      id="floatingName"
                      value={name}
                      placeholder="name@example.com"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <label className="ps-0 mb-3">Your Name</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="text"
                      pattern="[0-9]{10}"
                      title="Please enter valid mobile."
                      className="form-control input-box px-1"
                      id="mobile"
                      value={mobile}
                      placeholder="name@example.com"
                      onInvalid={(e) => {
                        return e.target.setCustomValidity("Please enter valid mobile number.");
                      }}
                      onChange={(e) => {
                        setMobile(e.target.value);
                        return e.target.setCustomValidity('');
                      }}
                      required
                    />
                    <label className="ps-0">Your Mobile Number</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control input-box px-1"
                      id="email"
                      value={email}
                      placeholder="name@example.com"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label className="ps-0">Your e-mail address</label>
                  </div>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control input-box px-1"
                      id="company_name"
                      value={company_name}
                      placeholder="name@example.com"
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <label className="ps-0">Company Name</label>
                  </div>
                  <div className="textarea-container">
                    <textarea
                      className="form-control label"
                      id="message"
                      placeholder="Type Your Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows="4"
                      style={{ backgroundColor: "#eee" }}
                    ></textarea>
                  </div>
                  <div className="text-center mt-4">
                    <Button
                      type={isSending ? "button" : "submit"}
                      variant="primary"
                      className={pageType === 'aboutUs' ? 'btn-main' : "btn-main"}
                      style={{ borderRadius: 10 + 'px' }}
                    >
                      {isSending ? (
                        <span>
                          <Spinner
                            className="me-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          SENDING ...
                        </span>
                      ) : (
                        "SEND MESSAGE"
                      )}
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center my-3">
                    <Image src="/assets/svgs/get-in-touch/credence_logo.svg" alt="credence hr service logo" style={{ width: 150 }} />
                  </div>
                  <div className="text-center my-3 bottom-text">
                    Organization doesn't accomplish anything. Theories of <br />{" "}
                    management don't matter much. Endeavours succeed or
                    <br /> fail because of the people involved.
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Breakpoint>
      <Breakpoint
        className="mt-3 pt-5"
        style={is_light ? null : styles.contactFormContainer}
        medium
        down
      >
        <Row>
          <h5
            className={`text-center ${is_light ? "" : "text-white"}`}
            style={{ fontWeight: 700, fontSize: 24 }}
          >
            Get in touch
          </h5>
          <Col sm={12}>
            <Form className={`px-2 pt-4 formbox`} onSubmit={sendEmail}>
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control px-1 ${is_light ? "input-box" : "input-box-colored"
                    }`}
                  id="floatingName"
                  value={name}
                  placeholder="name@example.com"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <label className={`ps-0 mb-3 ${is_light ? "" : "text-white"}`}>
                  Your Name
                </label>
              </div>
              <div className="form-floating">
                <input
                  type="mobile"
                  pattern="[0-9]{10}"
                  title="Please enter valid mobile."
                  className={`form-control px-1 ${is_light ? "input-box" : "input-box-colored"
                    }`}
                  id="mobile"
                  value={mobile}
                  placeholder="name@example.com"
                  onInvalid={(e) => {
                    e.target.setCustomValidity("Please enter valid mobile number.");
                  }}
                  onChange={(e) => {
                    setMobile(e.target.value);
                    return e.target.setCustomValidity('');
                  }}
                  required
                />
                <label className={`ps-0 ${is_light ? "" : "text-white"}`}>
                  Your Mobile Number
                </label>
              </div>
              <div className="form-floating">
                <input
                  type="email"
                  className={`form-control px-1 ${is_light ? "input-box" : "input-box-colored"
                    }`}
                  id="email"
                  value={email}
                  placeholder="name@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label className={`ps-0 ${is_light ? "" : "text-white"}`}>
                  Your e-mail address
                </label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control px-1 ${is_light ? "input-box" : "input-box-colored"
                    }`}
                  id="company_name"
                  value={company_name}
                  placeholder="name@example.com"
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <label className={`ps-0 ${is_light ? "" : "text-white"}`}>
                  Company Name
                </label>
              </div>
              <div className="textarea-container mt-3">
                <textarea
                  className="form-control label"
                  id="message"
                  placeholder="Type Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="4"
                  style={{ backgroundColor: "#eee" }}
                ></textarea>
              </div>
              <div className="text-center mt-4">
                <Button
                  type="submit"
                  variant="primary"
                  className="py-2 btn-main btn-mobile"
                  style={{ borderRadius: 10 + 'px' }}
                >
                  {isSending ? (
                    <span>
                      <Spinner
                        className="me-2"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      SENDING ...
                    </span>
                  ) : (
                    "SEND MESSAGE"
                  )}
                </Button>
              </div>
              <div
                className={`text-center my-3 bottom-text text-mobile ${is_light ? "" : "text-white"
                  }`}
              >
                Organization doesn't accomplish anything. Theories of management
                don't matter much. Endeavours succeed or fail because of the
                people involved.
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Image
                  src="/assets/svgs/get-in-touch/objects.svg"
                  className="contactform-mobile-bg"
                  alt='objects'
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Breakpoint>
    </Row>
  );
};

const styles = {
  contactFormContainer: {
    fontFamily: "Montserrat Regular",
    backgroundColor: "#02493C",
  },
  contactFormContainerLu: {
    fontFamily: "Montserrat Regular",
    backgroundColor: "#fff",
  }
};

export default ContactForm;
