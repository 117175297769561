import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import "./Blogs.scss";
import Footer from "components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";

const Blogs = () => {
  const [sortBy, setSortBy] = useState("Latest");
  const [searchText, setSearchText] = useState("");
  const [blogsArray, setBlogsArray] = useState([]);
  const [loadMoreLink, setLoadMoreLink] = useState("");
  const [loading, setLoading] = useState(true);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };

  const sortMap = {
    Latest: "-published_date",
    Oldest: "published_date",
    Alphabetical: "title",
  };

  const generateSearchString = (o, s) => {
    let searchString = "/blogs/";

    if (!o) {
      searchString += `?search=${s}&ordering=${sortMap[sortBy]}`;
    }

    if (!s) {
      searchString += `?search=${searchText}&ordering=${sortMap[o]}`;
    }
    return searchString;
  };

  const fetchBlogs = async () => {
    const results = await axios.get("/blogs/?limit=12");
    if (results) {
      console.warn(results);
      setBlogsArray(results.data.results);
      setLoadMoreLink(results.data.next);
      setLoading(false);
    } else {
      console.error(results);
    }
  };

  const searchBlogs = async () => {
    if (searchText?.length >= 2) {
      setLoading(true);
      try {
        const results = await axios.get(
          generateSearchString(undefined, searchText)
        );
        if (results) {
          console.warn(results);
          setBlogsArray(results.data);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    } else {
      fetchBlogs();
    }
  };

  const getMoreBlogs = async () => {
    const results = await axios.get(loadMoreLink);
    if (results) {
      console.warn(results);
      setBlogsArray([...blogsArray, ...results.data.results]);
      setLoadMoreLink(results.data.next);
    } else {
      console.error(results);
    }
  };

  useEffect(() => fetchBlogs(), []);

  const formSubmit = (e) => {
    e.preventDefault();
    searchBlogs();
  };

  const changeSort = async (val) => {
    setSortBy(val);
    setLoading(true);
    try {
      const results = await axios.get(generateSearchString(val, undefined));
      if (results) {
        console.warn(results);
        setBlogsArray(results.data);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };
  return (
    <>
      <Container
        className="p-0 mt-5 mb-5 pt-5 d-flex flex-column"
        fluid
        style={styles.parentContainer}
      >
        <Container fluid className="px-md-5">
          <Row>
            <Form className="px-4 px-md-5" onSubmit={(e) => formSubmit(e)}>
              <Row>
                <Col className="col-10 col-sm-4">
                  <InputGroup
                    style={{ height: 48 + "px" }}
                    className="mb-4 search justify-content-center align-items-center"
                  >
                    <Form.Control
                      placeholder="Search Here"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div className="p-2 cursor-pointer">
                      <img
                        src="/assets/svgs/blogs/search.svg"
                        width={24}
                        height={24}
                        alt="search"
                        onClick={() => {
                          searchBlogs();
                        }}
                      />
                    </div>
                  </InputGroup>
                </Col>
                <Col className="col-3 col-sm-3 d-none d-sm-block">
                  <DropdownButton
                    variant="outline-secondary"
                    title={sortBy}
                    id="input-group-dropdown-1"
                    style={{ height: 48 + "px" }}
                  >
                    <Dropdown.Item
                      onClick={() => changeSort("Latest")}
                      href="#"
                    >
                      Latest
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeSort("Oldest")}
                      href="#"
                    >
                      Oldest
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeSort("Alphabetical")}
                      href="#"
                    >
                      Alphabetical
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col className="col-2 col-sm-3 d-sm-none">
                  <DropdownButton
                    variant="outline-secondary"
                    title={
                      <img
                        src="/assets/svgs/blogs/filter.svg"
                        alt="filter"
                        className="filter-icon"
                      />
                    }
                    id="input-group-dropdown-1"
                    className="dropdown-mobile justify-content-end"
                    style={{ height: 48 + "px" }}
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={() => changeSort("Latest")}
                    >
                      Latest
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => changeSort("Oldest")}
                    >
                      Oldest
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => changeSort("Alphabetical")}
                    >
                      Alphabetical
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Row>
            </Form>
          </Row>
          <Row className="mb-4">
            <h1 className="px-4 px-md-5 mb-0">Blogs</h1>
          </Row>
          <Row
            style={{ minHeight: 600 }}
            className={
              loading ? "justify-content-center align-items-center" : ""
            }
          >
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <>
                {blogsArray?.length > 0 ? (
                  blogsArray.map((item) => {
                    return (
                      <Col md={4} key={item?.id}>
                        <Link
                          style={{ textDecoration: "none" }}
                          // href={"/blogs/" + item.slug}
                          // onClick={() =>
                          //   handleNavigationClick("/blogs/" + item.slug)
                          // }
                          to={"/blogs/" + item.slug}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <img
                              src={item.blog_image}
                              alt={item.title}
                              style={styles.blogImageStyle}
                            />
                            <p className="blogs-title px-3 px-md-4">
                              {item.title}
                            </p>
                          </div>
                        </Link>
                      </Col>
                    );
                  })
                ) : (
                  <Container
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 600 }}
                  >
                    <p>No blogs available.</p>
                  </Container>
                )}
              </>
            )}
          </Row>
          {loadMoreLink && (
            <Row>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <p style={{ color: "#00A85A" }} onClick={() => getMoreBlogs()}>
                  LOAD MORE &#8595;
                </p>
              </div>
            </Row>
          )}
        </Container>
      </Container>
      <Footer />
    </>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    maxWidth: 1366 + "px",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
  blogImageStyle: {
    borderRadius: 24 + "px",
    width: 343 + "px",
    height: 220 + "px",
    objectFit: "cover",
  },
};

export default Blogs;
