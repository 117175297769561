import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import NavBar from "components/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { useCurrentBreakpointName } from "react-socks";
import { increment1, decrement1 } from "actions";
import RecruiterCard from "components/RecruiterCard";
import "./HomeCandidate.scss";
import ContactForm from "components/ContactForm";

const Candidate6 = () => {
  const dispatch = useDispatch();

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      dispatch(decrement1());
    }
    if (event.nativeEvent.wheelDelta < -50) {
    }
  };

  return (
    <Container
      className="p-0 pt-5 bg-get-in-touch position-absolute top-0"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar  /> */}
      <Container className="px-0 px-md-5 h-100 d-flex flex-column" fluid>
        <ContactForm page="Candidate"/>
        <div
          className="d-flex flex-column position-fixed"
          style={{ right: 50, bottom: 50 }}
        >
          <Image
            className="arrow my-2"
            src="/assets/svgs/get-in-touch/arrow_up.svg"
            alt="Arrow up"
            onClick={() => {
              dispatch(decrement1());
            }}
          />
          <Image
            className="arrow"
            src="/assets/svgs/get-in-touch/arrow_down.svg"
            alt="Arrow Down"
            style={{opacity: 0.4}}
          />
        </div>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    backgroundColor: "#02493C",
    minHeight:100+'vh'
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Candidate6;
