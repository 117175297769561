import React, { useState, useEffect } from "react";
import LandingNavbar from "components/NavBar/LandingNavbar";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./Landing.scss";
import axios from "axios";
import Footer from "components/Footer";

const Landing = () => {
  const { slug } = useParams();
  const [jobpost, setJobpost] = useState(null);

  const handleNavigationClick = (url) => {
    window.location.href = url;
  };
  const doStuff = async () => {
    const response = await axios.get(`jobpost/${slug}`);
    if (response.status === 200) {
      setJobpost(response.data);
    }
  };

  useEffect(() => doStuff(), []);

  return (
    <>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center career-background montserrat pt-5"
        style={styles.parentContainer}
      >
        <Row className="w-100 h-100 mx-4 justify-content-between">
          <Col lg={6} className="d-flex align-items-center">
            <div className="mx-2 d-flex flex-column justify-content-between align-items-center align-items-md-start upper-section">
              <h2 className="montserrat-700 title-text">{jobpost?.title}</h2>
              <h4 className="my-3 subtitle-text">{jobpost?.job_role}</h4>
              <p className="body-text text-center text-md-start">
                {jobpost?.description}
              </p>
              <Button
                // as={Link}
                // to={`/landing/applying/${slug}`}
                onClick={() =>
                  handleNavigationClick(`/landing/applying/${slug}`)
                }
                className="wrapped-button border-0 mt-3 mt-md-5 apply-button"
              >
                APPLY
              </Button>
            </div>
          </Col>
          <Col
            lg={6}
            className="d-flex flex-column justify-content-end justify-content-md-center overflow-hidden"
            style={{ maxWidth: "100%" }}
          >
            <Image
              className=" overflow-hidden"
              src="/assets/landing/landing_character.svg"
              alt="Landing Character"
              style={{ width: 90 + "%" }}
            />
          </Col>
        </Row>
      </Container>
      <Container className="p-0" fluid>
        <Footer />
      </Container>
    </>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
  },
};

export default Landing;
