import React, { useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Carousel,
  Card,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated, useSpring } from "react-spring";

import {
  Breakpoint,
  useCurrentBreakpointName,
  useCurrentWidth,
} from "react-socks";
import { increment1, decrement1 } from "actions";
import NavBar from "components/NavBar";
import "./HomeCandidate.scss";
import Footer from "components/Footer";
import ContactForm from "components/ContactForm";
import * as easings from "d3-ease";

const feedbacks = [
  {
    id: 1,
    content:
      "Thanks for your support. The entire process with Credence HR Services was so impressive, in future if I have any references, I would surely  recommend Credence HR Services. I would Rate 9.5 out of 10. I am so thankful to the entire team of Credence HR Services.",
    author: "Rajesh M",
    role: "",
  },
  {
    id: 2,
    content:
      "Just want to take a moment to thankyou for providing the support from explaining the job opportunity, coordinating interviews and seamless onboarding through Credence HR Services. Thank you for always being proactive in explaining all the process. Kudos to you and your team!!",
    author: "Priya Srivastava",
    role: "Technical Lead",
  },
  {
    id: 3,
    content:
      "I would like to thank you & Credence HR Services for the placement with Kantar & giving me the opportunity. I appreciate your cooperation throughout the process.",
    author: "Ankita Chatterjee",
    role: "Sr Analyst (Project Manager)",
  },
  {
    id: 4,
    content:
      "I really thank you for such a smooth hiring Process and for guiding me at each step. I really appreciate the polite behavior and answering all my queries at any time of the day. I am thankful to Credence for this great opportunity.",
    author: "Ankita Rastogi",
    role: "Sr Analyst (Creative Solution)",
  },
  {
    id: 5,
    content:
      "I am sending this thank you note for the fantastic job. Thank you Credence HR Services. Thank you for your guidance and support. Credence HR Services really helpful. ",
    author: "Abhishek Borse",
    role: "Sr Analyst (Creative Solution)",
  },
  {
    id: 6,
    content:
      "I want to say a massive thank you to Credence for your support and for always being on hand to offer help. I love that you have taken the time to understand my objectives, guide me at each step, and with being so flexible with your time. Keep up the good work!",
    author: "Sakshi Mor",
    role: "Analyst",
  },
  {
    id: 7,
    content:
      "I got a good opportunity in FIS through Credence, and I am very Thankful. Also, a Big vote of Thanks to Divya for conducting the whole process and resolving all my queries by timely update and guidance.",
    author: "Harsh Maheshwari",
    role: "Technical Support Specialist",
  },
];

const clients = [
  {
    id: 1,
    set: [
      { id: 4, name: "HSBC", alt: "HSBC", img: "/assets/clients/hsbc.png" },
      {
        id: 3,
        name: "Credit Suisse",
        img: "/assets/clients/credit_suisse.png",
        alt: "Credit Suisse"
      },
      {
        id: 2,
        name: "Deutsche Bank",
        img: "/assets/clients/deutsche_bank.png",
        alt: "Deutsche Bank"
      },
      { id: 1, name: "JP-Morgan", alt: "JP Morgan", img: "/assets/clients/jp_morgan.png" },
      { id: 6, name: "Fis", alt: "FIS", img: "/assets/clients/fis.png" },
      {
        id: 5,
        name: "Northern Trust",
        img: "/assets/clients/northern_trust.png",
        alt: "Northern Trust"
      },
      { id: 10, name: "HSBC", alt: "HSBC Bank", img: "/assets/clients/hsbc.png" },
    ],
  },
  {
    id: 2,
    set: [
      { id: 7, name: "JP-Morgan", alt: "", img: "/assets/clients/jp_morgan.png" },
      {
        id: 8,
        name: "Deutsche Bank",
        img: "/assets/clients/deutsche_bank.png",
        alt: "Deutsche Bank"
      },
      {
        id: 9,
        name: "Credit Suisse",
        img: "/assets/clients/credit_suisse.png",
        alt: "Credit Suisse"
      },
      { id: 4, name: "HSBC", alt: "HSBC Bank", img: "/assets/clients/hsbc.png" },
      {
        id: 3,
        name: "Credit Suisse",
        img: "/assets/clients/credit_suisse.png",
        alt: "Credit Suisse"
      },
      {
        id: 2,
        name: "Deutsche Bank",
        img: "/assets/clients/deutsche_bank.png",
        alt: "Deutsche Bank"
      },
      { id: 1, name: "JP-Morgan", alt: "JP Morgan", img: "/assets/clients/jp_morgan.png" },
    ],
  },
];

const Candidate1 = () => {
  const dispatch = useDispatch();
  const contactRef = useRef();

  const [text, setText] = useState(true);
  const count = useSelector((state) => state.counters);

  const [scrolled, setScrolled] = useState(false);

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      // setPreviousPage(currentPage);
      // setCurrentPage(currentPage - 1);
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (!scrolled) {
        setText(false);
        dispatch(increment1());
      }
      setScrolled(true);
    }
  };

  const textTransition = useTransition(text, {
    from:
      count.candidatePrevState > count.candidateCurrentState
        ? { y: -200, opacity: 0 }
        : { y: 0, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -200, opacity: 0 },
  });

  const width = useCurrentWidth();

  const breakpoint = useCurrentBreakpointName();
  let to1 = {};
  let to2 = {};
  let wheelWidth = 0;
  let wheelBorderRadius = 0;
  let imageSrc = "";
  let wheelTop = 0;
  let wheelLeft = 0;
  let wheelLeftForRight = 0;
  let leftDiff = 0;

  switch (breakpoint) {
    case "xxlarge":
      wheelWidth = 1100;
      wheelBorderRadius = 550;
      imageSrc = "/assets/images/home/dial-candidate.png";
      wheelTop = -500;
      wheelLeft = -925;
      wheelLeftForRight = -800;
      leftDiff = -32;
      break;
    case "xlarge":
      wheelWidth = 1100;
      wheelBorderRadius = 550;
      imageSrc = "/assets/images/home/dial-candidate.png";
      wheelTop = -500;
      wheelLeft = -925;
      wheelLeftForRight = -800;
      leftDiff = -32;
      break;
    case "large":
      wheelWidth = 1100;
      wheelBorderRadius = 550;
      imageSrc = "/assets/images/home/dial-candidate.png";
      wheelTop = -500;
      wheelLeft = -925;
      wheelLeftForRight = -800;
      leftDiff = -32;
      break;
    case "medium":
      wheelWidth = 1100;
      wheelBorderRadius = 550;
      imageSrc = "/assets/images/home/dial-candidate.png";
      wheelTop = -500;
      wheelLeft = -925;
      wheelLeftForRight = -800;
      leftDiff = -32;
      break;
    default:
      wheelWidth = 1100;
      wheelBorderRadius = 550;
      imageSrc = "/assets/images/home/dial-candidate.png";
      wheelTop = -500;
      wheelLeft = -835;
      wheelLeftForRight = -800;
      leftDiff = -32;
  }

  const [animatingLeft, setAnimatingLeft] = useState(true);
  const [animating, setAnimating] = useState(true);
  const [index, setIndex] = useState(0);

  const springProps1 = useSpring({
    from: {
      index1: `rotate(-18deg)`,
      index2: `rotate(-12deg)`,
      index3: `rotate(-6deg)`,
      index4: `rotate(-24deg)`,
      index5: `rotate(-6deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-120000deg)`,
      index2: `rotate(-12036deg)`,
      index3: `rotate(-1248deg)`,
      index4: `rotate(-42deg)`,
      index5: `rotate(-6deg)`,
    },
    config: { duration: 4000, easing: easings.easeCubicOut },
    delay: 1000,
    onRest: () => {
      setAnimatingLeft(false);
    },
  });

  const [deg, setDeg] = useState(0);

  const springProps1Slow = useSpring({
    loop: true,
    from: {
      index1: `rotate(0deg)`,
      index2: `rotate(-36deg)`,
      index3: `rotate(-48deg)`,
      index4: `rotate(-42deg)`,
      index5: `rotate(-6deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-840deg)`,
      index2: `rotate(-120deg)`,
      index3: `rotate(-60deg)`,
      index4: `rotate(-48deg)`,
      index5: `rotate(-6deg)`,
    },
    config: { duration: 720000 },
    delay: 8000,
  });

  const springProps2 = useSpring({
    from: {
      index1: `rotate(0deg)`,
      index2: `rotate(0deg)`,
      index3: `rotate(-42deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-1200deg)`,
      index2: `rotate(-120deg)`,
      index3: `rotate(-54deg)`,
    },
    config: { duration: 4000, easing: easings.easeCubicOut },
    delay: 1000,
    onRest: () => {
      setAnimating(false);
      scrolled = false;
    },
  });

  const springProps2Slow = useSpring({
    loop: true,
    from: {
      index1: `rotate(0deg)`,
      index2: `rotate(0deg)`,
      index3: `rotate(-54deg)`,
    },
    // from: { index1: -160, index2: -960, index3: -1440, index4: -2400, index5: -3680 },
    to: {
      index1: `rotate(-540deg)`,
      index2: `rotate(-54deg)`,
      index3: `rotate(-54deg)`,
    },
    config: { duration: 720000 },
    delay: 16000,
  });

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <React.Fragment>
      <Breakpoint large up>
        <Container
          className="p-0 m-0 overflow-hidden"
          fluid
          style={styles.parentContainer}
          onWheel={handleWheel}
        >
          {/* <NavBar/> */}
          <Container fluid>
            <Row>
              <Col
                lg={6}
                className="d-flex align-items-center justify-content-center"
              >
                {textTransition((props, item) => {
                  if (item) {
                    return (
                      <animated.div style={props}>
                        <div className="home-stats-candidate">
                          {/* <Button
                onClick={() => {
                  setDeg(6);
                }}
              >
                Click
              </Button> */}
                          <div className="recruiter-main-sidetext ">
                            we have helped
                          </div>
                          <div className="d-flex justify-content-center mb-1 bouncing-letter-candidate position-relative overflow-hidden">
                            <animated.div
                              className="p-0 position-absolute"
                              style={{
                                transform: animatingLeft
                                  ? springProps1.index1
                                  : springProps1Slow.index1,
                                borderRadius: wheelBorderRadius,
                                width: wheelWidth,
                                height: wheelWidth,
                                top: wheelTop,
                                left: wheelLeft,
                              }}
                            >
                              <Image
                                src={imageSrc}
                                alt="dial-candidate"
                                className="position-relative"
                              />
                            </animated.div>
                            <animated.div
                              className="p-0 position-absolute"
                              style={{
                                transform: animatingLeft
                                  ? springProps1.index2
                                  : springProps1Slow.index2,
                                borderRadius: wheelBorderRadius,
                                width: wheelWidth,
                                height: wheelWidth,
                                top: wheelTop,
                                left: wheelLeft + leftDiff,
                              }}
                            >
                              <Image
                                src={imageSrc}
                                alt="dial-candidate"
                                className="position-relative"
                              />
                            </animated.div>
                            <animated.div
                              className="p-0 position-absolute"
                              style={{
                                transform: animatingLeft
                                  ? springProps1.index3
                                  : springProps1Slow.index3,
                                borderRadius: wheelBorderRadius,
                                width: wheelWidth,
                                height: wheelWidth,
                                top: wheelTop,
                                left: wheelLeft + leftDiff * 2,
                              }}
                            >
                              <Image
                                src={imageSrc}
                                alt="dial-candidate"
                                className="position-relative"
                              />
                            </animated.div>
                            <animated.div
                              className="p-0 position-absolute"
                              style={{
                                transform: animatingLeft
                                  ? springProps1.index4
                                  : springProps1Slow.index4,
                                borderRadius: wheelBorderRadius,
                                width: wheelWidth,
                                height: wheelWidth,
                                top: wheelTop,
                                left: wheelLeft + leftDiff * 3,
                              }}
                            >
                              <Image
                                src={imageSrc}
                                alt="dial-candidate"
                                className="position-relative"
                              />
                            </animated.div>
                            <animated.div
                              className="p-0 position-absolute"
                              style={{
                                transform: springProps1.index5,
                                borderRadius: wheelBorderRadius,
                                width: wheelWidth,
                                height: wheelWidth,
                                top: wheelTop,
                                left: wheelLeft + leftDiff * 4,
                              }}
                            >
                              <Image
                                src={imageSrc}
                                alt="dial-candidate"
                                className="position-relative"
                              />
                            </animated.div>
                          </div>
                        </div>
                        <div className="recruiter-main-sidetext ">
                          individuals like you...
                        </div>
                      </animated.div>
                    );
                  } else {
                    return <div />;
                  }
                })}
              </Col>
              <Col
                lg={6}
                className="home-col-right pt-5 background-candidate"
                style={{ height: "100vh" }}
              >
                <Row>
                  <Col className="px-5 pt-5" lg={12}>
                    <h3
                      className="text-left pt-5 ms-5 ps-4"
                      style={{ fontWeight: 600, fontSize: 1.3 + 'rem' }}
                    >
                      Candidate testimonial
                    </h3>
                    <p
                      className="text-left ms-5 mt-3 ps-4"
                      style={{ fontSize: 16, fontWeight: 600 }}
                    >
                      {`${index + 1} of ${feedbacks.length}`}
                    </p>
                    <Carousel
                      prevIcon={
                        <Image
                          src="assets/svgs/candidate/carousel-arrow-left.svg"
                          alt="carousel-arrow-left"
                          style={{ marginRight: 100 }}
                        />
                      }
                      indicators={false}
                      prevLabel={null}
                      nextLabel={null}
                      interval={5000}
                      nextIcon={
                        <Image
                          src="/assets/svgs/candidate/carousel-arrow-right.svg"
                          alt="carousel-arrow-right"
                          style={{ marginLeft: 100 }}
                        />
                      }
                      activeIndex={index}
                      onSelect={handleSelect}
                    >
                      {feedbacks.map((item, index) => {
                        return (
                          <Carousel.Item
                            key={item.id}
                            className="text-start px-5 pt-1 overflow-visible"
                            style={{ height: "60vh" }}
                          >
                            <p
                              className="text-white mt-3 px-4 candidate-carousel-content"
                              dangerouslySetInnerHTML={{ __html: item.content }}
                            ></p>
                            <div className="px-4">
                              <Image
                                src="/assets/svgs/candidate/author-separator.svg"
                                alt="author-separator"
                                className="my-4"
                              />
                              <div className="d-flex author-text">
                                <div
                                  className="me-3 text-white"
                                  style={{ fontWeight: 600 }}
                                >
                                  -
                                </div>
                                <div>
                                  <span className="text-white" style={{ fontWeight: 600 }}>
                                    {item.author}
                                  </span>
                                  <br />
                                  <span className="text-white" style={{ fontSize: 14 }}>
                                    {item.role}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </Col>
                </Row>
                <div
                  className="d-flex flex-column position-fixed"
                  style={{ right: 50, bottom: 50, zIndex: 1000 }}
                >
                  <Image
                    className="arrow my-2"
                    src="/assets/svgs/get-in-touch/arrow_up.svg"
                    alt="Arrow up"
                    style={{ opacity: 0.4 }}
                  />
                  <Image
                    className="arrow"
                    src="/assets/svgs/get-in-touch/arrow_down.svg"
                    alt="Arrow Down"
                    onClick={() => {
                      setText(false);
                      dispatch(increment1());
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </Breakpoint>
      <Breakpoint medium down>
        <Container fluid className="mt-5 pt-3">
          <Row>
            <div
              className="mt-5 text-center home-stats-candidate"
              style={{ color: "#001E24", fontSize: 56, fontWeight: 700 }}
            >
              <div className="d-flex justify-content-center bouncing-letter position-relative overflow-hidden">
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: animatingLeft
                      ? springProps1.index1
                      : springProps1Slow.index1,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: animatingLeft
                      ? springProps1.index2
                      : springProps1Slow.index2,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft + leftDiff,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: animatingLeft
                      ? springProps1.index3
                      : springProps1Slow.index3,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft + leftDiff * 2,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: animatingLeft
                      ? springProps1.index4
                      : springProps1Slow.index4,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft + leftDiff * 3,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: springProps1.index5,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft + leftDiff * 4,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
              </div>
            </div>
            <div
              className="my-5 text-center"
              style={{ color: "#001E24", fontSize: 36, fontWeight: 600 }}
            >
              Candidate Placed through us
            </div>
            <div className="text-center">
              <Button
                className="btn-main"
                onClick={() => {
                  window.open("/get-in-touch", "_blank");
                }}
              >
                CONTACT US
              </Button>
            </div>
          </Row>
          <Row>
            <Carousel
              indicators={true}
              prevLabel={null}
              nextLabel={null}
              prevIcon={null}
              nextIcon={null}
              fade
              variant="dark"
              className="my-3 p-0"
            >
              {clients.map((item, index) => {
                return (
                  <Carousel.Item
                    key={item.id}
                    className="text-start d-flex align-items-center justify-content-center"
                  >
                    <Container className="py-5 px-0">
                      <div className="d-flex justify-content-center mb-4">
                        <div className="company-box d-flex justify-content-center align-items-center mx-3">
                          <Image
                            className="company-box-img"
                            src={item.set[0].img}
                            alt="company-box-image"
                          />
                        </div>
                        <div className="company-box d-flex justify-content-center align-items-center mx-3">
                          <Image
                            className="company-box-img"
                            src={item.set[1].img}
                            alt="company-box-image"
                          />
                        </div>
                        <div className="company-box d-flex justify-content-center align-items-center mx-3">
                          <Image
                            className="company-box-img"
                            src={item.set[2].img}
                            alt="company-box-image"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mb-4">
                        <div className="company-box d-flex justify-content-center align-items-center mx-3">
                          <Image
                            className="company-box-img"
                            src={item.set[3].img}
                            alt="company-box-image"
                          />
                        </div>
                        <div className="company-box d-flex justify-content-center align-items-center mx-3">
                          <Image
                            className="company-box-img"
                            src={item.set[4].img}
                            alt="company-box-image"
                          />
                        </div>
                        <div className="company-box d-flex justify-content-center align-items-center mx-3">
                          <Image
                            className="company-box-img"
                            src={item.set[5].img}
                            alt="company-box-image"
                          />
                        </div>
                      </div>
                    </Container>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Row>
          <Row>
            <Container
              fluid
              className="my-4 p-0 candidates-opinions-carousel-box"
            >
              <div
                className="text-center mt-5"
                style={{ fontSize: 24, fontWeight: 700 }}
              >
                What they say!
              </div>
              <Carousel
                indicators={true}
                prevLabel={null}
                nextLabel={null}
                prevIcon={null}
                nextIcon={null}
                fade
                variant="dark"
                className=""
              >
                {feedbacks.map((item, index) => {
                  return (
                    <Carousel.Item
                      key={item.id}
                      className="text-start d-flex align-items-center justify-content-center"
                    >
                      <div className="pt-2 pb-5">
                        <div
                          className="d-flex flex-column justify-content-center align-items-center h-100 user-select-none text-white text-center p-3"
                          style={{ fontSize: 20 }}
                        >
                          {item.content}
                        </div>
                        <div className="d-flex align-self-center justify-content-center client-card-footer px-2 text-center mt-2">
                          <div
                            className="d-flex author-text p-2"
                            style={{ borderTop: "2px solid white", bottom: 0 }}
                          >
                            <div>
                              <span style={{ fontWeight: 600 }}>
                                {item.author}
                              </span>
                              <br />
                              <span style={{ fontSize: 14 }}>{item.role}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Container>
          </Row>
          <Row>
            <div
              className="my-4 text-center"
              style={{ color: "#001E24", fontSize: 24, fontWeight: 700 }}
            >
              Open opportunities for you
            </div>
            <div
              className="mt-4 text-center home-stats-candidate"
              style={{ color: "#001E24", fontSize: 56, fontWeight: 700 }}
            >
              <div className="d-flex justify-content-center mb-1 bouncing-letter position-relative overflow-hidden">
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: animating
                      ? springProps2.index1
                      : springProps2Slow.index1,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft - 30,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: animating
                      ? springProps2.index2
                      : springProps2Slow.index2,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft + leftDiff - 30,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
                <animated.div
                  className="p-0 position-absolute"
                  style={{
                    transform: animating
                      ? springProps2.index3
                      : springProps2Slow.index3,
                    borderRadius: wheelBorderRadius,
                    width: wheelWidth,
                    height: wheelWidth,
                    top: wheelTop,
                    left: wheelLeft + leftDiff * 2 - 30,
                  }}
                >
                  <Image src={imageSrc} alt="dial-candidate" className="position-relative" />
                </animated.div>
              </div>
            </div>
            <div className="text-center my-4">
              <Button
                className="btn-main"
                onClick={() => {
                  window.open("/get-in-touch", "_blank");
                }}
              >
                GET IN TOUCH
              </Button>
            </div>
          </Row>
          <Container className="p-0" fluid ref={contactRef}>
            <Container
              className={`d-flex flex-column p-0 ${width >= 992 ? "bg-get-in-touch" : ""
                }`}
              fluid
            >
              <ContactForm
                pageType="aboutUs"
                is_light={false}
                page="Candidate"
              />
            </Container>
          </Container>
          <Container className="p-0" fluid>
            <Footer />
          </Container>
        </Container>
      </Breakpoint>
    </React.Fragment>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Candidate1;
