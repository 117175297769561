import React, {useState} from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
} from "react-router-dom";
import { useTransition, animated } from "react-spring";
import Candidate1 from "screens/Home/HomeCandidate/Candidate1";
import Candidate2 from "screens/Home/HomeCandidate/Candidate2";
import Candidate3 from "screens/Home/HomeCandidate/Candidate3";
import Candidate4 from "screens/Home/HomeCandidate/Candidate4";
import Candidate5 from "screens/Home/HomeCandidate/Candidate5";
import Candidate6 from "screens/Home/HomeCandidate/Candidate6";

const arr = [
  {
    key: 1,
    state: true,
    element: <Candidate1 />,
  },
  {
    key: 2,
    state: false,
    element: <Candidate2 />,
  },
  {
    id: 3,
    element: <Candidate3 />,
  },
  {
    id: 4,
    element: <Candidate4 />,
  },
  {
    id: 5,
    element: <Candidate5 />,
  },
  {
    id: 6,
    element: <Candidate6 />,
  },
];

const HomeCandidateNavigation = (params) => {
  const count = useSelector((state) => state.counters);

  const [items, setItems] = useState(arr);
  const element = items[count.candidateCurrentState - 1];
  console.log(count);

  const transitions = useTransition(element, {
    key: (item) => item.key,
    initial: { life: "0%", opacity: 0, height: 0 },
    enter: (item) => [{ opacity: 1, height: "100%" }, { life: "100%" }],
    leave: (item) => async (next, cancel) => {
      await next({ life: "0%" });
      await next({ opacity: 0 });
      await next({ height: 0 });
    },
    from: { life: "0%", opacity: 1, height: 0 },
    config: { duration: 1000 },
  });

  console.log(count);

  // return transitions((props, item) => {
  //     console.log(props);
  //     console.log(item);
  //     return (
  //         <animated.div style={props}>
  //             <Switch location={item}>
  //                 <Route exact path={path}>
  //                     <HomeCandidate />
  //                 </Route>
  //                 <Route path={`${path}/1`}>
  //                     <Candidate1 />
  //                 </Route>
  //                 <Route path={`${path}/2`}>
  //                     <Candidate2 />
  //                 </Route>
  //                 <Route path={`${path}/3`}>
  //                     <Candidate3 />
  //                 </Route>
  //                 <Route path={`${path}/4`}>
  //                     <Candidate4 />
  //                 </Route>
  //                 <Route path={`${path}/5`}>
  //                     <Candidate5 />
  //                 </Route>
  //             </Switch>
  //         </animated.div>
  //     );
  // }
  // );

  // return (
  //     <Router>
  //         <Switch >
  //             <Route exact path={path}>
  //                 <HomeCandidate />
  //             </Route>
  //             <Route path={`${path}/1`}>
  //                 <Recruitment1 />
  //             </Route>
  //             <Route path={`${path}/2`}>
  //                 <Recruitment2 />
  //             </Route>
  //         </Switch>
  //     </Router>
  // );

  return (
    <Container fluid className="p-0">
      {transitions((props, item) => {
        console.log(item);
        return (
          <animated.div as={Container} style={props} fluid>
            {item ? item.element : <div />}
          </animated.div>
        );
      })}
    </Container>
  );
};

export default HomeCandidateNavigation;
