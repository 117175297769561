import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import NavBar from "components/NavBar";
import RecruiterCard from "components/RecruiterCard";
import "./HomeRecruiter.scss";
import { useTransition, animated } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment, switchToPage } from "actions";
import { useCurrentBreakpointName } from "react-socks";
import "components/RecruiterCard/RecruiterCard.scss";

const Recruitment2 = React.memo((props) => {
  const dispatch = useDispatch();

  const count = useSelector((state) => state.counters);

  const breakpoint = useCurrentBreakpointName();
  let items = {};

  switch (breakpoint) {
    case "xxlarge":
      items = [
        {
          id: 8,
          img: "/assets/svgs/recruiter/User8.svg",
          // from: {
          //   transform: `translate(330px,-55px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: 330,
            y: -55,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: 98,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 10,
          img: "/assets/svgs/recruiter/User4.svg",
          // from: {
          //   transform: `translate(-50px,65px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: -50,
            y: 55,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: 50,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 14,
          img: "/assets/svgs/recruiter/User6.svg",
          // from: {
          //   transform: `translate(-205px,185px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: -205,
            y: 185,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: 5,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 2,
          img: "/assets/svgs/recruiter/User2.svg",
          // from: {
          //   transform: `translate(-355px,-170px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: -355,
            y: -170,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: -10,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
      ];
      break;
    case "xlarge":
      items = [
        {
          id: 8,
          img: "/assets/svgs/recruiter/User8.svg",
          // from: {
          //   transform: `translate(330px,-55px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: 330,
            y: -55,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: 98,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 10,
          img: "/assets/svgs/recruiter/User4.svg",
          // from: {
          //   transform: `translate(-50px,65px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: -50,
            y: 55,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: 50,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 14,
          img: "/assets/svgs/recruiter/User6.svg",
          // from: {
          //   transform: `translate(-205px,185px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: -205,
            y: 185,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: 5,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 2,
          img: "/assets/svgs/recruiter/User2.svg",
          // from: {
          //   transform: `translate(-355px,-170px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: {
            x: -355,
            y: -170,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter: {
            x: 0,
            y: 0,
            opacity: 1,
            scale: 1,
            borderWidth: 2,
            borderColor: "#001e24",
          },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: {
            x: -10,
            y: -28,
            opacity: 1,
            scale: 0.8,
            borderWidth: 0,
            borderColor: "#fff",
          },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
      ];
      break;
    default:
      items = [
        {
          id: 8,
          img: "/assets/svgs/recruiter/User8.svg",
          from: { x: 470, y: -70, opacity: 1, scale: 0.8, borderWidth: 0 },
          enter: { x: 0, y: 0, opacity: 1, scale: 1, borderWidth: 2 },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: { x: 0, y: 0, opacity: 1, scale: 1 },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 10,
          img: "/assets/svgs/recruiter/User4.svg",
          // from: {
          //   transform: `translate(-65px,85px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: { x: -65, y: -85, opacity: 1, scale: 0.8, borderWidth: 0 },
          enter: { x: 0, y: 0, opacity: 1, scale: 1, borderWidth: 2 },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: { x: 0, y: 0, opacity: 1, scale: 1 },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 14,
          img: "/assets/svgs/recruiter/User6.svg",
          // from: {
          //   transform: `translate(-280px,230px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: { x: -280, y: 230, opacity: 1, scale: 0.8, borderWidth: 0 },
          enter: { x: 0, y: 0, opacity: 1, scale: 1, borderWidth: 2 },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: { x: 0, y: 0, opacity: 1, scale: 1 },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
        {
          id: 2,
          img: "/assets/svgs/recruiter/User2.svg",
          // from: {
          //   transform: `translate(-490px,-219px) scale(0.8, 0.8)`,
          //   opacity: 1,
          // },
          from: { x: -490, y: -219, opacity: 1, scale: 0.8 },
          enter: { x: 0, y: 0, opacity: 1, scale: 1 },
          leave: { x: 0, y: 0, opacity: 0 },
          from1: { x: 0, y: 0, opacity: 1, scale: 1 },
          enter1: { x: 0, y: 0, opacity: 1 },
        },
      ];
  }

  let scrolled = false;
  const [disable, setDisable] = useState(false);

  // console.log(previousPage, currentPage)
  const transitions = useTransition(items, {
    key: (item) => item.key,
    from: (item) =>
      count.recruiterPrevState > count.recruiterCurrentState
        ? item.from1
        : item.from,
    enter: (item) => item.enter,
    leave: (item) => item.leave,
    config: { duration: 1500, tension: 200 },
    onRest: () => {
      scrolled = true;
    },
  });

  const [text, setText] = useState(true);

  const textTransition = useTransition(text, {
    from:
      count.recruiterPrevState > count.recruiterCurrentState
        ? { y: 0, opacity: 0 }
        : { y: 200, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 200, opacity: 0 },
    config: { duration: 1000, tenstion: 200 },
  });

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        setDisable(true);
        setText(false);
        dispatch(decrement());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      console.log(scrolled);
      if (scrolled) {
        setDisable(true);
        dispatch(increment());
      }
    }
  };

  return (
    <Container
      className="p-0 pt-5 d-flex flex-column position-absolute"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container className="p-5" fluid>
        <Row style={{ height: "100%", marginTop: "10%" }}>
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-start px-5"
          >
            <div>
              {textTransition((props, item) =>
                item ? (
                  <animated.div
                    style={props}
                    className="recruiter-main-sidetext my-4"
                  >
                    <div>
                      Because recruitment is critical, <br /> We help you get
                      the match right
                    </div>
                    <Button
                      type="button"
                      className="mt-5 btn-main align-items-center"
                      onClick={() => {
                        dispatch(switchToPage(2, 6));
                      }}
                    >
                      <h4>GET IN TOUCH</h4>
                    </Button>
                  </animated.div>
                ) : (
                  <div />
                )
              )}
            </div>
          </Col>
          <Col lg={6} className="text-center px-5 mt-5 py-5">
            <div>
              <Row>
                {/* {items.map((item, index) => {
                  return (
                    <Col lg={3} key={item.id} style={item.from}>
                      <RecruiterCard
                        cardType="recruiter1"
                        key={item.id}
                        item={item}
                        border={item.from.border}
                      />
                    </Col>
                  );
                })} */}
                {transitions((props, item) => {
                  if (item) {
                    return (
                      <Col as={animated.div} lg={3} key={item.id} style={props}>
                        {!disable && (
                          <Card
                            as={animated.div}
                            className="recruiter1Card d-flex justify-content-center align-items-center my-3"
                            style={{
                              borderWidth: props.borderWidth,
                              borderColor: props.borderColor,
                            }}
                          >
                            <Image
                              className="recruiter1CardImg"
                              src={item.img}
                              alt={item.alt}
                              rounded
                            />
                          </Card>
                        )}
                      </Col>
                    );
                  } else {
                    return <div />;
                  }
                })}
              </Row>
            </div>
            <div
              className="d-flex flex-column position-fixed"
              style={{ right: 50, bottom: 50 }}
            >
              <Image
                className="arrow my-2"
                src="/assets/svgs/recruiter/arrow_up.svg"
                alt="Arrow up"
                onClick={() => {
                  setDisable(true);
                  setText(false);
                  dispatch(decrement());
                }}
              />
              <Image
                className="arrow"
                src="/assets/svgs/recruiter/arrow_down.svg"
                alt="Arrow Down"
                onClick={() => {
                  setDisable(true);
                  dispatch(increment());
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
});

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    overflow: "hidden",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Recruitment2;
