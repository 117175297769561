import React, { useState } from "react";
import { Container, Row, Col, Image, Carousel, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";

import { useCurrentBreakpointName } from "react-socks";
import { increment1, decrement1, switchToPage1 } from "actions";
import { Link } from "react-router-dom";
import NavBar from "components/NavBar";

import "./HomeCandidate.scss";

const items = [
  {
    id: 1,
    content:
      "Thanks for your support. The entire process with Credence HR Services was so impressive, in future if I have any references, I would surely recommend Credence HR Services. I would Rate 9.5 out of 10. I am so thankful to the entire team of Credence HR Services.",
    author: "Rajesh M",
  },
  {
    id: 2,
    content:
      "Just want to take a moment to thankyou for providing the support from explaining the job opportunity, coordinating interviews and seamless onboardingthrough Credence HR Services. Thank you for always being proactive in explaining all the process.Kudos to you and your team!!",
    author: "Priya Srivastava",
  },
];

const Candidate3 = () => {
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(true);

  const count = useSelector((state) => state.counters);

  let scrolled = false;

  const cardImgTransition = useTransition(toggle, {
    from:
      count.candidatePrevState > count.candidateCurrentState
        ? { y: 0, opacity: 0 }
        : { y: 200, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 200, opacity: 0 },
    config: { duration: 1000, tenstion: 200 },
    onRest: () => {
      scrolled = true;
    },
  });

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        dispatch(decrement1());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (scrolled) {
        dispatch(increment1());
      }
    }
  };

  return (
    <Container
      className="p-0 pt-5 d-flex flex-column overflow-hidden"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container className="h-100 d-flex flex-column justify-content-center">
        <Row className="my-4">
          <Col
            lg={12}
            className="d-flex align-items-center justify-content-center mt-5"
          >
            <div className="recruiter-main-sidetext text-center">
              Because you need to stand out in the crowd, we not only help you
              <br /> find the right job, we also offer you the following
              services
            </div>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            {cardImgTransition((props, item) =>
              item ? (
                <animated.div
                  className="d-flex justify-content-center"
                  style={props}
                >
                  <Image
                    className="candidate2-images"
                    src="/assets/svgs/candidate/candidate2/one_page_resume.svg"
                    alt="one-page-resume"
                  />
                </animated.div>
              ) : (
                <div />
              )
            )}
            <div className="text-center candidate2-images-text mt-4">
              1 Page Resume Development
            </div>
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            {cardImgTransition((props, item) =>
              item ? (
                <animated.div
                  className="d-flex justify-content-center"
                  style={props}
                >
                  <Image
                    className="candidate2-images"
                    src="/assets/svgs/candidate/candidate2/career_counselling.svg"
                    alt="career-counselling"
                  />
                </animated.div>
              ) : (
                <div />
              )
            )}
            <div className="text-center candidate2-images-text mt-4">
              Career Counselling
            </div>
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            {cardImgTransition((props, item) =>
              item ? (
                <animated.div
                  className="d-flex justify-content-center"
                  style={props}
                >
                  <Image
                    className="candidate2-images"
                    src="/assets/svgs/candidate/candidate2/aptitude_test.svg"
                    alt="aptitude-test"
                  />
                </animated.div>
              ) : (
                <div />
              )
            )}
            <div className="text-center candidate2-images-text mt-4">
              Aptitude Test
            </div>
            <div
              className="d-flex flex-column position-fixed"
              style={{ right: 50, bottom: 50 }}
            >
              <Image
                className="arrow my-2"
                src="/assets/svgs/candidate/arrow_up.svg"
                alt="Arrow up"
                onClick={() => {
                  dispatch(decrement1());
                }}
              />
              <Image
                className="arrow"
                src="/assets/svgs/candidate/arrow_down.svg"
                alt="Arrow Down"
                onClick={() => {
                  dispatch(increment1());
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="text-center my-4">
          <div>
            <Button
              as={Link}
              type="link"
              className="btn-main"
              onClick={() => {
                dispatch(switchToPage1(3, 6));
              }}
            >
              <div>GET IN TOUCH</div>
            </Button>
          </div>
        </Row>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
    overflow: 'hidden'
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Candidate3;
