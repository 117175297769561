import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AboutUs from "screens/AboutUs";

const AboutUsNavigation = (params) => {
  return (
    <Router>
      <Switch>
        <Route exact path="">
          <AboutUs />
        </Route>
      </Switch>
    </Router>
  );
};

export default AboutUsNavigation;
