import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Card,
  Carousel,
} from "react-bootstrap";
import NavBar from "components/NavBar";
import RecruiterCard from "components/RecruiterCard";
import "./HomeRecruiter.scss";
import { RecruiterContext } from "navigation/HomeNavigation/HomeRecruiterNavigation/RecruiterContext";
import { useDispatch, useSelector } from "react-redux";
import { increment, decrement } from "actions";
import { animated, useTransition } from "react-spring";

const clients = [
  {
    id: 1,
    from: {
      x: -260,
      y: 6,
      scaleY: 0.38,
      scaleX: 0.25,
      backgroundColor: "#FFEACC",
    },
    // enter: { x: -260, y: 6, scaleY: 0.38, scaleX: 0.25 },
    enter: { x: 0, y: 0, scaleY: 1, scaleX: 1, backgroundColor: "#fff" },
    leave: { opacity: 0 },
    text: "Thank you ‘Credence HR Services’ for your continued support & contribution.",
    author_company: "Synchrony International Services",
    author_name: "Vice President - HR",
  },
  {
    id: 2,
    from: {
      x: -455,
      y: 6,
      scaleY: 0.38,
      scaleX: 0.25,
      backgroundColor: "#FFEACC",
    },
    // enter: { x: -455, y: 6, scaleY: 0.38, scaleX: 0.25 },
    enter: { x: 0, y: 0, scaleY: 1, scaleX: 1, backgroundColor: "#fff" },
    leave: { opacity: 0 },
    text: "It is a reliable, proactive and efficient HR consultancy. They keep end to end track of their candidates and follow up in a timely manner. I wish them the very best.",
    author_company: "VP at an Investment Bank ",
    author_name: null,
  },
  {
    id: 3,
    from: {
      x: -646,
      y: 6,
      scaleY: 0.38,
      scaleX: 0.25,
      backgroundColor: "#DCEFDD",
    },
    // enter: { x: -646, y: 6, scaleY: 0.38, scaleX: 0.25 },
    enter: { x: 0, y: 0, scaleY: 1, scaleX: 1, backgroundColor: "#fff" },
    leave: { opacity: 0 },
    text: "They are a bunch of well trained & skilled recruiters who consistently support me in my aim to hire the best talent available.",
    author_company: "Teamlead Recruitment ",
    author_name: "A Technology Consulting Firm",
  },
];

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const Recruitment5 = () => {
  let scrolled = false;

  const handleWheel = (event) => {
    if (event.nativeEvent.wheelDelta > 50) {
      if (scrolled) {
        dispatch(decrement());
      }
    }
    if (event.nativeEvent.wheelDelta < -50) {
      if (scrolled) {
        dispatch(increment());
      }
    }
  };

  const scrollRef = useRef(null);

  const scroll = async (scrollOffset) => {
    let counter = 0;
    let absScrollOffset = scrollOffset < 0 ? -1 * scrollOffset : scrollOffset;
    // alert(scrollOffset);
    while (counter < absScrollOffset) {
      scrollOffset > 0
        ? (scrollRef.current.scrollLeft += 20)
        : (scrollRef.current.scrollLeft -= 20);
      counter += 20;
      await sleep(10);
    }
  };

  const dispatch = useDispatch();
  const count = useSelector((state) => state.counters);

  const transitions = useTransition(clients, {
    key: (item) => item.id,
    from: (item) => item.from,
    enter: (item) => item.enter,
    leave: (item) => item.leave,
    config: { duration: 1000, tension: 200 },
    onRest: () => {
      scrolled = true;
    },
  });

  return (
    <Container
      className="p-0 pt-5 d-flex flex-column position-absolute"
      fluid
      style={styles.parentContainer}
      onWheel={handleWheel}
    >
      {/* <NavBar /> */}
      <Container style={{ width: "75%" }}>
        <Row>
          <Col lg={12} className="mt-5 py-2 px-5">
            <h3
              className="recruiter-main-sidetext text-center"
              style={{ fontWeight: 600 }}
            >
              What our client says
            </h3>
          </Col>

          <Col lg={12} className="text-center">
            <div
              className="d-flex flex-column position-fixed"
              style={{ right: 50, bottom: 50 }}
            >
              <Image
                className="arrow my-2"
                src="/assets/svgs/recruiter/arrow_up.svg"
                alt="Arrow up"
                onClick={() => {
                  dispatch(decrement());
                }}
              />
              <Image
                className="arrow"
                src="/assets/svgs/recruiter/arrow_down.svg"
                alt="Arrow Down"
                onClick={() => {
                  dispatch(increment());
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        className="d-flex flex-row justify-content-center align-items-center"
        fluid
      >
        <div>
          <Image
            src="/assets/svgs/recruiter/recruiter4/left-arrow.png"
            alt="Arrow Left"
            className="position-relative"
            style={{ left: 30, zIndex: 1000, cursor: "pointer" }}
            onClick={() => {
              scroll(-500);
            }}
          />
        </div>
        <div
          className="d-flex py-5 px-2 cards-container position-relative"
          style={{ overflowX: "scroll", width: "70%" }}
          ref={scrollRef}
        >
          {transitions((props, item) =>
            item ? (
              <div>
                <animated.div
                  className="client-card border-0 shadow position-relative px-5"
                  style={props}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center h-75 user-select-none text-center">
                    {item.text}
                  </div>
                  <div
                    className="d-flex justify-content-center align-self-center client-card-footer px-2"
                  >
                    <p
                      className="px-2 py-1 text-center"
                      style={{ borderTop: "2px solid black"}}
                    >
                      <span style={{ fontWeight: 600 }}>{item.author_company}</span>
                      <br />
                      <span>{item.author_name}</span>
                    </p>
                  </div>
                </animated.div>
              </div>
            ) : (
              <div />
            )
          )}
        </div>
        <div>
          <Image
            src="/assets/svgs/recruiter/recruiter4/right-arrow.png"
            alt="Arrow Down"
            className="position-relative"
            style={{ right: 30, zIndex: 1000, cursor: "pointer" }}
            onClick={() => {
              scroll(500);
            }}
          />
        </div>
      </Container>
    </Container>
  );
};

const styles = {
  parentContainer: {
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Recruitment5;
