import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { Container } from "react-bootstrap";

import HomeNavigation from "./HomeNavigation";
import AboutUsNavigation from "./AboutUsNavigation";
import CeoCornerNavigation from "./CeoCornerNavigation";
import GetInTouchNavigation from "./GetInTouchNavigation";
import AnimationScreen from "./AnimationScreen";
import customHistory from "customHistory";

// import Home from 'screens/Home';
// import Home from 'screens/Home';
// import Home from '../screens/Home';
// import Home from "../screens/Home";
// import customHistory from 'customHistory';
import NavBar from "components/NavBar";
import Footer from "components/Footer";
import Explore from "screens/Home/Explore";
import Home from "screens/Home";
import HomeCandidateNavigation from "navigation/HomeNavigation/HomeCandidateNavigation";
import HomeRecruiterNavigation from "navigation/HomeNavigation/HomeRecruiterNavigation";

import ContactForm from "components/ContactForm";
import { useCurrentWidth } from "react-socks";
import { Helmet } from "react-helmet";
import Rocket from "screens/Landing/Rocket";
import JobPost from "screens/Landing/JobPost";
import Landing from "screens/Landing";
import Blogs from "screens/Blogs";
import Blog from "screens/Blog";
import PageNotFound from "screens/PageNotFound";
import MaintenanceMode from "screens/MaintenanceMode";

import axios from "axios";

const Navigation = (props) => {
  console.log(props);
  console.log(customHistory);

  const count = useSelector((state) => state.counters);

  const width = useCurrentWidth();

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const location = useLocation();

  return (
    <Router history={customHistory}>
      <Container
        className="p-0 d-flex flex-column"
        fluid
        style={styles.parentContainer}
      >
        {process.env.REACT_APP_MAINTENANCE_MODE === "true" ? (
          <Container className="p-0 h-100" fluid>
            <Switch>
              <Route path="*">
                <MaintenanceMode />
              </Route>
            </Switch>
          </Container>
        ) : (
          <>
            <NavBar />
            <Container className="p-0 h-100" fluid>
              <Switch>
                <Route path="/about-us/" component={AboutUsNavigation} />
                <Route path="/blogs/:slug" component={Blog} />
                <Route path="/blogs">
                  {/* <Helmet>
                  <title>{`Learn about recruitment, hiring, job market & related matters`}</title>
                  <meta name="description" content='Learn today to earn tomorrow. Read our blogs on recruitment, job market and related matters to know more about the industry. Happy Reading and Job Hunting.' />
                  <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
                  <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
                </Helmet> */}
                  <Blogs />
                </Route>
                <Route
                  path="/ceo-corner/"
                  exact
                  component={CeoCornerNavigation}
                />
                {/* <Route path="/get-in-touch/" exact>
                <GetInTouchNavigation />
              </Route> */}
                {/* <Route path="/animation/" exact>
                <AnimationScreen />
              </Route> */}
                <Route path="/explore">
                  <Explore />
                </Route>
                <Route path="/home">
                  <>
                    {/* <Helmet>
                    <title>{`Leading HR Services | Job Consultancy| Recruitment |Manpower`}</title>
                    <meta name="description" content='We have got you covered, whether you are looking to hire or looking to get hired. Discover one of best job consultancy near me for jobs in IT and in Banking.' />
                    <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
                    <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
                  </Helmet> */}
                    {console.log(count)}
                    {count.currentMode === "candidate" ? (
                      <HomeCandidateNavigation />
                    ) : (
                      <HomeRecruiterNavigation />
                    )}
                  </>
                </Route>
                <Route path="/get-in-touch">
                  {/* <Helmet>
                  <title>{`Jobs | Job Consultancy | HR Consultancy| Pan India Jobs`}</title>
                  <meta name="description" content='Looking for PAN India Jobs? Looking to Hire the Best Talent? The best jobs in IT and in Banking. Best Talent in India. Contact us for personalized support.' />
                  <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
                  <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
                </Helmet> */}

                  {/* <Helmet>
                  <meta charSet="utf-8" />
                  <title>
                    HR Recruiter Jobs in Pune | Recruitment Specialist Jobs |
                    Contact Us
                  </title>
                  <meta
                    name="description"
                    content="Grow your recruitment career with Credence HR Services. If you are looking for a recruiter job in Pune, then contact us!!!"
                  />
                </Helmet> */}
                  <Container
                    className={`my-0 py-0 overflow-auto ${width >= 992 ? "bg-get-in-touch" : ""
                      }`}
                    style={{
                      ...styles.parentContainer,
                      backgroundColor: "#02493C",
                    }}
                    fluid
                  >
                    <Container
                      className={`d-flex flex-column p-0 pt-3 mt-3 mt-lg-5`}
                      fluid
                    >
                      <ContactForm is_light={false} page="Candidate" />
                    </Container>
                  </Container>
                </Route>
                <Route path="/contact-us">
                  <Container
                    className={`my-0 py-0 overflow-auto ${width >= 992 ? "bg-get-in-touch" : ""
                      }`}
                    style={{
                      ...styles.parentContainer,
                      backgroundColor: "#02493C",
                    }}
                    fluid
                  >
                    <Container
                      className={`d-flex flex-column p-0 pt-3 mt-3 mt-lg-5`}
                      fluid
                    >
                      <ContactForm is_light={false} page="Candidate" />
                    </Container>
                  </Container>
                </Route>
                <Route path="/landing/apply/:slug">
                  <JobPost />
                </Route>
                <Route path="/landing/applying/:slug">
                  <Rocket />
                </Route>
                <Route path="/landing/:slug">
                  {/* <Helmet>
                  <title>{`Get Recognized | Learn from the leader | Earn More | Apply for Job`}</title>
                  <meta name="description" content='Apply for a role with Credence. See your career in HR soar. Work with the best and learn as you go along. Money & recognition are in plenty as well. Apply Now.' />
                  <link rel="canonical" href={`https://www.credencehrservices.com${location.pathname}`} />
                  <link rel="alternate" hreflang="x-default" href={`https://www.credencehrservices.com${location.pathname}`} />
                </Helmet> */}
                  <Landing />
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </Container>
          </>
        )}
      </Container>
    </Router>
  );
};

const styles = {
  parentContainer: {
    height: "100vh",
    fontFamily: "Montserrat Regular",
  },
  navItems: {
    letterSpacing: 1,
    fontWeight: "normal",
    fontSize: 20,
    color: "#fff",
  },
};

export default Navigation;
